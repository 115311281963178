import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewContainerRef,
  TemplateRef,
  QueryList,
  ChangeDetectorRef
} from '@angular/core';

import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ToastService } from 'src/app/services/toast.service';
import { HostListener } from '@angular/core';
import { Subtest, TestI } from 'src/models/test';
import { Questions } from 'src/models/test/questions';
import { SubtestRules } from 'src/models/test/subtestRules';
import { ResponseAll } from 'src/models/test/responseAll';
import { TestRules } from 'src/models/test/testrules';
import { QuestionnaireData } from 'src/models/test/questionnaireData';
import { QuestionnaireResponse } from 'src/models/test/questionnaireResponse';
import { OptionResp } from 'src/models/test/optionResp';
import { ResponseItem } from 'src/models/test/responseItem';
import { SubSink } from 'subsink';
import { TestService } from 'src/app/services/test.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { inOutAnimation } from 'src/assets/animations/in-out-animation';
import { ColorChangeService } from 'src/app/services/color-change.service';
import { ItemsAlignString } from 'src/app/enums/itemsAlign.enum';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  providers: [ConfirmationService, ToastService],
  animations: [inOutAnimation]
})
export class TestComponent implements OnInit, AfterViewInit {

  @ViewChild('title', { read: ElementRef }) childcomp: QueryList<ElementRef>;
  @ViewChild('title') title: ElementRef;
  @ViewChild('viewcontainer', { read: ViewContainerRef }) viewcontainer;
  @ViewChild(TemplateRef) template: TemplateRef<null>;
  @ViewChild('size', { static: true }) size: ElementRef;
  @ViewChild('container') container: ElementRef;

  public entryLastPoint = [];
  changesSaved = false;

  public data: TestI[] = [];
  public allQuestions: any[] = [];
  public questions: Questions[] = [];
  public OptionRespose: OptionResp[] = [];
  public testTitle: string;
  public indexSubTest: number = 0;
  public auxPuntero: number = 0;
  public Nresp: number = 0;
  public valSlider: number;
  public valQuestion: number = 0;
  public totalpreg: number = 0;
  public dialog: boolean = false;
  public position: string;
  public sendMultipleresponse: boolean = false;
  public activeState: boolean = false;
  public showNonResponseQuestions: boolean = false;
  public subtestRules: any;
  public send: boolean = false;
  public showOne: number = 0;
  public userInteractedWithCurrentPage: boolean = false;

  // guardo el indice de las preguntas no respondidas para comprobaciones
  public indexNoResp: any = [];

  // Reglas del test
  public mandatory: boolean = false;
  public timerFinish: boolean = false;
  public itemScreen: number;
  public indexScreen: number = 0;
  public instruction: string;

  // public auxPuntero1: number=0;
  public punteroTest: number = 0;

  public punteroTestStructure: number = 0;

  public indexId: number = 0;
  public saveResponse: boolean = false;

  public auxGroup: number[] = [];
  public itemGroup: number[] = [];
  public type: string[] = [];

  // TIMER
  public timeLeft: any;
  public interval;
  private countdownDiff: number;
  private timeout = false;

  // objeto principal de las respuestas
  public questionaire: ResponseAll;

  public testRules: TestRules[] = [];

  // subtest
  public continueSubtest: boolean = false;
  public numSubtest: number = 0;
  public acceso: boolean = false;

  // pregunta seleccionada
  private prevSelected: any;

  public allResponses: String[] = [];

  // para enviar todas las respuestas
  public questionnaireData: QuestionnaireData;
  public questionaireResponses: QuestionnaireResponse[] = [];
  public subtest: Subtest;

  // objeto con las respuesta del usuario a cada pregunta
  public selectedResponse: any = [];
  public auxResponse: any = [];

  public checkResponses: any = [];
  // Array de index para itemsPerScreen
  public objIndexPerScreen: any;
  public indexItemsPerScreen: any = [];

  // contenedor de todos los test del usuario
  public testStructures: any = [];

  // caracteristicas del config de cada test
  public getResponses: any[] = [];

  public sharedAnswers: any;

  public qCode: any;
  // tslint:disable-next-line: member-ordering
  public sharedCode: any;

  // default text-size
  fontSize = 20;
  increase = true;
  decrease = true;

  subs = new SubSink();

  test: any = {};
  subTest: any;
  currentTest: any;

  // page indexes
  indexPage = 0;
  maxIndexPage = 0;
  itemsNumber = 0;

  // finished: para impedir que se sigan enviando datos una vez finalizado, esto sirve para el control de flujo de tiempo ya que almacena
  // el ultimo minuto cuando sale de la página
  private finished = false;

  // sirve para indicar si el test es recoverableSession, esto se utiliza solo para el flujo ux, no para la regla
  public isRecoverableSession = true;

  // para archivar que el usuario ha cancelado el cartel de finish
  private finishCancel = false;

  // para comprobar que la carga inicial se ha completado
  loaded = false;

  reloadAnimation = true;

  // para habilitar o no el botón de siguiente/finalizar en función de si los items de tipo checkbox cumplen con el min/max
  enableNextFinish = true;

  // get enviroment version
  currentAppVersion = environment.appVersion;

  under500 = false;

  // diferencia en segundos para el contador
  difference: number;

  // indica que se ha pulsado sobre una de las respuestas
  responseClicked = false;

  // muestra u oculta el botón de scroll
  showScroll = false;

  // flag para prevenir o no el cerrar la pestaña/ventana
  preventExit = false;

  // to thow the tool buttons in responsive mode
  speedDialItems = [];

  // items alignment
  itemsAlign: any;
  minLeftSpace = Infinity;
  public itemsAlignEnum = ItemsAlignString;

  // -------EVENTS--------
  // cuando el usuario cierra el navegador
  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHander($event): void {
    this.checkIfQuestionnaireShouldBeCompleted();
    // Si aparece el mensaje de test finalizado
    if(this.preventExit){
      $event.preventDefault();
      $event.returnValue = false;
    }
  }

  // cuando el usuario cambia de pestaña
  @HostListener('window:visibilitychange', [ '$event' ])
  visibilitychange(event): void {
    this.showCloseMessage();
  }

  // cuando el usuario va hacía atrás
  @HostListener('window:popstate', ['$event'])
  onPopState(event): any {
    this.showCloseMessage();
  }

  // Prevent default radio keyboard actions
  @HostListener('window:keydown', ['$event'])
  keyPressEvent(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();
    event.returnValue = false;
    event.cancelBubble = true;
    return false;
  }

  constructor(
    public dataSvc: DataService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private testSrv: TestService,
    private toastSrv: ToastService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private colorSrv: ColorChangeService
  ) {}

  ngOnInit(): void {
    // restart the current status test
    this.testSrv.currentTestFinished = false;
    this.subs.sink = this.dataSvc.sharedCurrentQcode.subscribe((data) => {
      this.qCode = data;
      this.subs.sink = this.testSrv.sharedCurrentTest.subscribe((test) => {
        this.currentTest = test;
        if (test !== null && test !== undefined) {
          this.subs.sink = this.testSrv.sharedCurrentSelectedSubtest.subscribe(subtestId => {

            this.subs.sink = this.testSrv.sharedCurrentStructures.subscribe(structures => {

              const sub = this.testSrv.getTestOfTestStructureBasedOnId(structures, subtestId);

              if (sub !== null) {
                this.initTest(test, sub);
              }

            });
        });
        }
      });
    });
    this.testSrv.setShowHelp(false);
    this.colorSrv.setIceBlueBackground();
    this.colorSrv.hideFooter();
    this.setSpeedDialButton();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  initTest(test, subtest): void {

    this.test = {...test};

    // order by item order
    let orderedSubtest = this.testSrv.orderSubtestItems({...subtest});

    // set index per page
    const result = this.testSrv.setPagesIndex(orderedSubtest);
    orderedSubtest = result.subtest;
    this.maxIndexPage = result.maxIndex;
    this.subTest = orderedSubtest;
    this.testRules = this.test.testRules;
    // si el test no tiene saveBy, establecemos item by default
    this.testRules['saveBy'] = this.testRules['saveBy'] ? this.testRules['saveBy'] : 'item';

    // generate items format
    this.testSrv.setItemsFormat(this.subTest);

    // TO-DO: Ver que sentido tiene esto
    this.questionaireResponses.length = this.test.subtests.length - 1;

    // datos generales del test
    this.subtest = Subtest.SubtestJson({
      subtestId: this.subTest.subtestId,
      subtestTitle: this.subTest.subtestTitle,
    });

    // cogemos todas las SubtestRules
    if (orderedSubtest.subtestRules) {
      this.subtestRules = SubtestRules.SubtestRulestJson(orderedSubtest.subtestRules);
      // si el test no tiene saveBy, establecemos item by default
      this.subtestRules.saveBy = this.subtestRules.saveBy ? this.subtestRules.saveBy : 'item';
    } else {
      // si no existe creo valores por defecto
      this.subtestRules = SubtestRules.SubtestRulestJson({
        itemsPerScreen: null,
        maxTime: null,
        canGoBack: true,
        canGoForward: true,
        maxNotAnswered: 0,
        recoverableSession: true,
        resetAnswers: false,
        instructions: '',
        mandatory: true,
      });
    }

    // Set selected response by order
    this.selectedResponse = this.testSrv.orderSelectedResponsesByTestRules(this.subTest.subtestRules.itemOrder, this.subTest.items);

    // comprobación si tiene instrucciones el subtest (y mira si las etiquetas de html vienen vacías)
    if (this.subtestRules.instructions && this.subtestRules.instructions.replace(/<(?:.|\n)*?>/gm, '').length > 0) {
      this.instruction = this.subtestRules.instructions;
    } else {
      // Cogemos las instrucciones del parent test
      this.instruction = this.test.testRules.instructions;
    }

    // Comprobacion de la regla recoverableSession
    if (this.subtestRules?.recoverableSession) {
      this.dataSvc.setRecoverableSession(this.subtestRules?.recoverableSession);
      this.testSrv.isRecoverableSession = true;
    } else {
      this.isRecoverableSession = false;
      this.testSrv.isRecoverableSession = false;
    }

    // Prueba para subtest
    orderedSubtest.items.map((question) => {
      this.type.push(question.responseOptions?.type);
      this.questions.push(Questions.questionJson(question));
      this.itemGroup.push(question.item_group);
    });
    this.allQuestions[this.indexSubTest] = this.questions;

    this.itemGroup.forEach((preg) => {
      if (preg != null) {
        this.totalpreg++;
      }
    });

    // para sacar solo las preguntas simples
    let uniqueQuestion;
    uniqueQuestion = this.itemGroup.length - this.totalpreg;
    this.auxGroup = this.itemGroup.filter((item, index) => {
      return this.itemGroup.indexOf(item) === index;
    });

    if (this.itemGroup[0] !== undefined) {
      this.valQuestion = 100 / (this.auxGroup.length - 2 + uniqueQuestion);
    } else {
      this.valQuestion = 100 / (this.auxGroup.length - 1 + uniqueQuestion);
    }
    this.valSlider = 0;

    this.loadPreviousSavedData(subtest);

    // Si hay tiempo
    if (this.subtestRules?.maxTime) {
      this.startTimer(this.subtestRules.maxTime);
    }

    // extrae el número de items que aparecen visualmente
    this.itemsNumber = this.subTest.items.filter(x => !x.multioptionParent).length;

    // ha finalizado de cargar el test
    this.loaded = true;

    this.changeClassBasedOnWidth();

    // Set items alignment or not (apply only when columns are only one)
    let singleColumn = true;
    this.questions.every(_quest => {
      if(_quest.responseOptions['columns'] && _quest.responseOptions['columns'] > 1){
        singleColumn = false;
        return false;
      }
      return true;
    });
    if (this.subtestRules?.itemsAlign && singleColumn) {
      this.itemsAlign = this.subtestRules?.itemsAlign;
    }
  }

  canDeactivate() {
    if (this.changesSaved) {
      return true;
    } else {
      return confirm(this.translate.instant('TEST.MSG-DEACTIVATE'));
    }
  }

  mensaje(): void {
    // Aviso de finalizar porque cumple con todo
    this.confirmationService.confirm({
      message: this.translate.instant('TEST.MSG-FINISH'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translate.instant('TEST.BTN-ACCEPT'),
      acceptIcon: '-', // caracter no válido para quitar los iconos
      rejectLabel: this.translate.instant('TEST.BTN-CANCEL'),
      rejectIcon: '-',
      accept: () => {
        this.acceso = true;
      },
      reject: () => {
        this.acceso = false;
      },
    });
  }

  loadPreviousSavedData(subtest): void {
    // Get previous response data
    this.dataSvc.getQuestionAnswered(this.qCode).then((data: any) => {

      this.entryLastPoint = data.entryLastPoint;

      const savedSubTestData = data.questionnaireResponses.find((x) => x.subtestId == subtest.subtestId || x.customId == subtest.customId);

      if (savedSubTestData) {
        // rellenar preguntas previamente respondidas
        if (this.selectedResponse.filter((x) => !x.responseValue).length === this.selectedResponse.length && savedSubTestData) {
          // comprobar si el test tiene habilitada la opción resetAnswers
          if (this.subtestRules.resetAnswers === false || this.subtestRules.resetAnswers === undefined) {
            // set responses in order
            this.selectedResponse = this.testSrv.orderSelectedResponsesByTestRules(this.subTest.subtestRules.itemOrder, savedSubTestData.responseItems);
            this.refillSlider();
          }
        }

        if (data.entryLastPoint !== null && data.entryLastPoint !== undefined) {

          let sbtEntryLastPoint;
          try{
            sbtEntryLastPoint = data.entryLastPoint.find(x => x.qCode === this.qCode && (x.subtestId === this.subTest.subtestId || x.customId == subtest.customId));
          } catch (e) { // do nothing
          }

          // comprobar resetAnswers
          if (this.subtestRules.resetAnswers === false || this.subtestRules.resetAnswers === undefined) {
            if (sbtEntryLastPoint) {
              const indexLastQuestion = savedSubTestData.responseItems.map((x) => x.itemId).indexOf(sbtEntryLastPoint.lastItem);

              // Para mover a la última página rellena
              if (indexLastQuestion >= 0 && sbtEntryLastPoint.lastPage <= this.maxIndexPage) {
                this.indexPage = sbtEntryLastPoint.lastPage;
                this.checkNonReponseQuestionsToPage(this.indexPage);
                this.generateEntryLastPoint(sbtEntryLastPoint.lastItem, this.indexPage, false);
              }
            } else {
              this.generateEntryLastPoint(1, this.indexPage, false);
            }
          }
        }
        this.checkResponseClickedStatus();
      }
    });
  }

  // movimiento del slider
  refillSlider(): void {
    const unit = 100 / this.subTest.items.filter(x => x.scoringItems || !x.multioptionParent).length;
    const filled = this.selectedResponse.filter((x) => x.responseValue !== undefined && x.responseValue !== null).length;
    this.valSlider = unit * filled;
  }

  // Modal test
  showPositionDialog(position: string) {
    this.position = position;
    this.dialog = true;
  }

  // TIMER
  startTimer(maxTime): void {

    this.dataSvc.getQuestionAnswered(this.qCode).then((data: any) => {

      // Fecha actual más el tiempo del test
      let countDownDate = moment();
      let countDownDateAux = moment();

      // ---- Check if exist previus data -----
      let previous = false;

      // recover difference in case of db storage
      const currentCountDown = data.entryLastPoint?.find((x) => x.subtestId === this.subtest?.subtestId && x.customId == this.subTest.customId)?.countdown;
      if (currentCountDown) {
        previous = true;
        countDownDate.add(currentCountDown, 's').toDate();
      }

      // recover difference in case of refresh
      const storedDifference = this.testSrv.currentCountdown.find(x => x.subtestId == this.subtest?.subtestId && x.customId == this.subTest.customId);

      if (storedDifference && storedDifference.difference > -1) {
        previous = true;
        if(currentCountDown){
          countDownDateAux.add(storedDifference.difference, 's').toDate();
          countDownDate = countDownDateAux;
        }else{
          countDownDate.add(storedDifference.difference, 's').toDate();
        }
      }

      if (!previous) {
        countDownDate.add(maxTime, 'm').toDate();
      }

      this.interval = setInterval(() => {
        // Fecha actual
        const now = moment();

        this.difference = countDownDate.diff(now, 'seconds');

        // store current status
        const savedData = this.testSrv.currentCountdown.filter(x => x.subtestId != this.subtest?.subtestId && x.customId != this.subtest['customId'])

        savedData.push({qCode: this.qCode, difference: this.difference, subtestId: this.subtest?.subtestId, customId: this.subTest.customId});
        this.testSrv.setCurrentCountdown(savedData);

        if (this.difference >= 3600) { // More than 60 minutes
          this.timeLeft = moment.utc(moment(countDownDate).diff(moment(now))).format('hh:mm:ss');
        } else {
          this.timeLeft = moment.utc(moment(countDownDate).diff(moment(now))).format('mm:ss');
        }

        if (this.difference < 0) {
          clearInterval(this.interval);
          this.timeLeft = '00:00';
          this.displayTimeoutMessage();
        }
      }, 1000);
    });
  }

  // Next Question
  nextQuestion(): void {

    if (this.subtestRules.canGoForward === true || this.responseClicked === true) {

      this.reloadAnimation = false;

      // comprueba si debe continuar o no
      this.enableDisableNextFinishButton();

      // si no tiene restricciones continua con el flujo
      if (this.enableNextFinish) {
        // comprueba si quedan preguntas sin responder antes de desplazar el index
        this.checkNonReponseQuestions(this.indexPage);

        // incrementa el indice de la página
        if (this.indexPage < this.maxIndexPage) {
          this.indexPage++;
          this.userInteractedWithCurrentPage=false;
        }

        // cambia el valor de la barra de estado
        this.refillSlider();

        // envio por pagina!
        if (this.test.subtests?.length > 1) {
          if (!this.testRules['saveBy'] || ['item', 'page'].includes(this.testRules['saveBy'].toLocaleLowerCase())) {
            this.saveAllResponse(2);
          }
        } else {
          if (this.testRules['saveBy'].toLocaleLowerCase() === 'page') {
            this.saveAllResponse(2);
          }
        }

        // avoid Expression changed error
        this.cdr.detectChanges();

        // Go to top of the screen when the user do click in nextQuestion
        window.scroll(0, 0);
      }

      this.reloadAnimation = true;

      this.checkResponseClickedStatus();

      this.changeClassBasedOnWidth();
    }
  }

  previousQuestion(): void {
    if (this.subtestRules.canGoBack === true) {
      this.reloadAnimation = false;

      // resetea el estado del bloqueo
      this.enableNextFinish = true;

      // comprueba si quedan preguntas sin responder antes de desplazar el index
      this.checkNonReponseQuestions(this.indexPage);

      // decrementa el índice
      if (this.indexPage > 0) {
        this.indexPage--;
        this.userInteractedWithCurrentPage=false;
      }

      // avoid Expression changed error
      this.cdr.detectChanges();

      // Go to top of the screen when the user do click in prevQuestion
      window.scroll(0, 0);

      this.reloadAnimation = true;

      this.checkResponseClickedStatus();

      this.changeClassBasedOnWidth();
    }
  }

  changeClassBasedOnWidth(): void{
    setTimeout( () => {
      if (this.container.nativeElement.offsetWidth < 501){
        this.under500 = true;
      } else {
        this.under500 = false;
      }
    }, 0 );
  }

  checkNonReponseQuestionsToPage(indexPage): void {
    for (let i = 0; i < indexPage; i++) {
      this.checkNonReponseQuestions(i);
    }
  }

  // comprueba si existen preguntas sin responder
  checkNonReponseQuestions(indexPage): void {
    this.subTest.items.filter((x) => x.index <= indexPage).forEach((x) => {
      let alreadySaved = this.indexNoResp.filter(z => z.itemId == x.itemId).length;
      // caso especial para multichoice
      if (x.scoringItems){
        let indexesMO = this.subTest.items.filter(z => z.multioptionParent == x.itemId).map(z => z.itemId);
        indexesMO.push(x.itemId);
        let response = this.selectedResponse.filter(z => indexesMO.includes(z.itemId) && z.responseValue);
        if (response.length <= 0 && alreadySaved <= 0) {
          this.indexNoResp.push({
            itemId: x.itemId,
            index: indexPage,
            parsedQuestion: x.parsedQuestion,
            position: x.position,
            mandatory: x.mandatory,
            scoringItems: x.scoringItems
          });
        }
        // para omitir las opciones que sean hijas de un multioption
      } else if (!x.multioptionParent) {
        const response = this.selectedResponse.filter(z => z.itemId === x.itemId)[0];
        if ((response.responseValue === null || response.responseValue === undefined) && alreadySaved <= 0) {
          this.indexNoResp.push({
            itemId: x.itemId,
            index: indexPage,
            parsedQuestion: x.parsedQuestion,
            position: x.position,
            mandatory: x.mandatory
          });
        }
      }
    });
  }

  // limpia las preguntas sin responder
  clearNonReponseQuestions(item): void {
    if (item.multioptionParent){
      this.indexNoResp = this.indexNoResp.filter(x => x.itemId !== item.multioptionParent);
    }else{
      this.indexNoResp = this.indexNoResp.filter(x => x.itemId !== item.itemId);
    }
  }

  showQuestion(question): void {
    this.indexPage = question.index;
    this.showNonResponseQuestions = false;
    try{
      setTimeout(() => {
        const el = document.getElementById(question.itemId) as HTMLElement;
        el.scrollIntoView({behavior: 'smooth', block: 'center'});
      }, 800);
    }catch (ex){}
  }

  // Cambiar el tamaño de la fuente
  sizeFont(val): void {
    if (val === '+') {
      this.fontSize += 2;
    } else {
      this.fontSize -= 2;
    }
    if (this.fontSize >= 30) {
      this.increase = false;
    } else {
      this.increase = true;
    }
    if (this.fontSize <= 16) {
      this.decrease = false;
    } else {
      this.decrease = true;
    }
  }

  checkIfCanSave(): void {
    // Realiza las comprobaciones de las preguntas con min/max y establece un valor para stopNextFinsh
    this.enableDisableNextFinishButton();

    // Comprueba si quedan preguntas sin responder
    this.checkNonReponseQuestions(this.indexPage);

    // Comprueba si tiene permiso para continuar
    if (!this.enableNextFinish) {
      return;
    }

    // Comprueba si aún quedan preguntas obligatorias
    if (!this.checkIfMandatoryAreAnswered()) {
      this.confirmationService.confirm({
        message: this.translate.instant('TEST.MSG-LEFT-MANDATORY-QUESTIONS'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translate.instant('TEST.BTN-ACCEPT'),
        acceptIcon: '-',
        rejectVisible: false,
        accept: () => {
          this.showNonResponseQuestions = true;
        }
      });
      return;
    }

    // Comprueba si el testaker ha rellenado el mínimo de preguntas necesarias
    if (!this.checkMaxNotAnswered()) {
      this.confirmationService.confirm({
        message: this.translate.instant('TEST.MSG-REQUIRED'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translate.instant('TEST.BTN-ACCEPT'),
        acceptIcon: '-',
        rejectVisible: false,
        accept: () => {
          this.showNonResponseQuestions = true;
        },
      });
      return;
    }

    // Si quedan preguntas sin responder pero puede finalizar
    if (this.indexNoResp.length > 0) {
      this.dataSvc.setMinResp(true);
      this.dataSvc.response[this.punteroTest] = this.questionaire;
      this.showOne++;
      this.confirmationService.confirm({
        message: this.translate.instant('TEST.MSG-LEFT-QUESTIONS'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translate.instant('TEST.BTN-SEND'),
        acceptIcon: '-',
        rejectLabel: this.translate.instant('TEST.BTN-CONTEST'),
        rejectIcon: '-',
        rejectVisible: true,
        accept: () => {
          this.saveAllResponse(1);
        },
        reject: () => {
          this.showNonResponseQuestions = true;
        },
      });
    } else { // Si ha respondido todas las preguntas y puede finalizar
      this.dataSvc.setAllOk(true);
      this.dataSvc.response[this.punteroTest] = this.questionaire;
      this.preventExit = true;
      this.confirmationService.confirm({
        message: this.translate.instant('TEST.MSG-CONFIRM-FINISH'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translate.instant('TEST.BTN-FINISH'),
        acceptIcon: '-',
        rejectLabel: this.translate.instant('TEST.BTN-CANCEL'),
        rejectIcon: '-',
        rejectVisible: true,
        accept: () => {
          this.saveAllResponse(1);
        },
        reject: () => {
          this.finishCancel = true;
        },
      });
    }
  }

  onClick(item: any, checkbox?: boolean): void {
    this.userInteractedWithCurrentPage=true
    // comprueba si es checkbox
    if (checkbox === true) {
      this.enableDisableNextFinishButton(item);
    }

    // Limpia este item de las preguntas sin responder
    this.clearNonReponseQuestions(item);
    // Aumenta el slider
    this.refillSlider();

    // Comprueba si es la última de las no respondidas
    this.checkIfIsTheLastNonResponsedQuestion();

    // enviar las respuestas cada vez que se selecciona una(Por item)
    this.generateEntryLastPoint(item.itemId, this.indexPage, false);

    // save always by item except if the save is based on page
    if ( !this.testRules['saveBy'] || this.testRules['saveBy'].toLocaleLowerCase() == 'item'
      || this.testRules['saveBy'].toLocaleLowerCase() != 'page') {
      this.saveAllResponse(2);
    }

    this.checkResponseClickedStatus()
  }

  /**
   * Habilita/deshabilita el botón para finalizar el test
   */
  enableDisableNextFinishButton(item?): void {
    this.enableNextFinish = false;

    // para comprobar en el onClick
    if (item) {
      const checkboxAnswersInLimits = this.checkMinMaxCheckbox(item);
      if (checkboxAnswersInLimits !== 0){
        this.enableNextFinish = true;
      }
    } else {
      // para comprobar en next/prev
      const checkBoxItems = this.showQuestionsByIndex(this.indexPage).filter(x => x.scoringItems);
      if (checkBoxItems.length > 0 ){
        checkBoxItems.forEach(x => {
          const checkboxAnswersInLimits = this.checkMinMaxCheckbox(x);
          if (checkboxAnswersInLimits !== 0){
            this.enableNextFinish = true;
          }
        });
      }
      else{
        this.enableNextFinish = true;
      }
    }

    if (!this.enableNextFinish) {
      // ajusta la altura del mensaje
      const msgBar = document.getElementsByClassName('p-toast-top-center')[0] as HTMLElement;
      if (document.documentElement.scrollTop > 0) {
        msgBar.classList.add('p-toast-top-center-desplaced');
      } else {
        msgBar.classList.remove('p-toast-top-center-desplaced');
      }
      // muestra el mensaje
      this.toastSrv.showToastWarning(this.translate.instant('TEST.MSG-MIN-MAX-WARN'));
    }
  }

  /**
   * Comprueba si el testaker ha rellenado el mínimo de preguntas necesarias
   */
   checkMaxNotAnswered(): boolean {
    let mandatory = 0;
    let countResp = 0;
    this.selectedResponse.forEach((element, index) => {
      if (!this.subTest.items[index].multioptionParent && !this.subTest.items[index].scoringItems) {
        if (element.responseValue == null) {
          countResp++;
          if (this.questions[index].mandatory) {
            mandatory++;
          }
        }
      }
    });

    if (mandatory > 0 || countResp > this.subtestRules?.maxNotAnswered || this.indexNoResp.length > this.subtestRules?.maxNotAnswered) {
      return false;
    }

    return true;
  }

  /**
   * comprueba si el checkbox cumple los parámetros
   * @param item;
   * @returns number;
   */
  checkMinMaxCheckbox(item: any): number {
    const id = item.multioptionParent ? item.multioptionParent : item.itemId;
    const relatedItems = this.subTest.items.filter((x) => x.itemId === id || x.multioptionParent === id);
    const parentItem = relatedItems.filter((x) => x.itemId === id)[0];

    if (parentItem.minimumOptionsRequired > 0 || parentItem.maximumOptionsRequired > 0) {
      let totalSelected = 0;
      let totalNotClicked = 0;

      relatedItems.forEach(x => {
        const itemValue = this.selectedResponse[x.position].responseValue;
        if (itemValue === true || itemValue === 1) {
          totalSelected++;
        }
        if (itemValue === null){
          totalNotClicked++;
        }
      });

      let minAllowed=parentItem.minimumOptionsRequired;
      let maxAllowed=parentItem.maximumOptionsRequired > 0 ? parentItem.maximumOptionsRequired : 1000000 //or no limit

      if(minAllowed<=totalSelected && totalSelected<=maxAllowed  || !this.userInteractedWithCurrentPage)
        return 1 //condition satisfied
      else
        return 0//condition not satisfied
    }
    // no tiene máximo/mínimo establecidos, satisface la condición
    return 1;
  }

  /**
   * @param index
   * @returns preguntas relativas al indice por parametros
   */
  showQuestionsByIndex(index): any {
    if (this.indexPage === this.maxIndexPage) {
      return this.subTest.items.filter((x) => x.index >= index);
    }
    return this.subTest.items.filter((x) => x.index === index);
  }

  /**
   * @returns string con el valor a mostrar en el indice visual del test
   */
  getQuestionsMinMax(): string {
    const questions = this.showQuestionsByIndex(this.indexPage);
    if (questions.length > 1) {
      // si se trata de un checkbox devuelvo el indice de la página
      const checkBoxs = questions.filter(x => x.scoringItems);
      if (checkBoxs.length === 1) {
        return this.indexPage + 1 + '';
      }

      const positions = questions.map(x => x.subposition).filter(x => x !== undefined);
      return `${Math.min.apply(null, positions) + 1}~${Math.max.apply(null, positions) + 1}`;
    }
    return questions[0].subposition + 1;
  }


  setQuestionnaireJsonBeforeSave(): any {

    let result = {};

    // HOT-FIX for avoid lastItem null or undefined issue
    if (!this.entryLastPoint) {
      this.generateEntryLastPoint(1, 1, false);
    }

    const formattedSelectedResponse = this.testSrv.formatSendObject(this.selectedResponse);

    // OBJETO PARA ENVIAR TODAS LAS RESPUESTAS
    this.questionnaireData = QuestionnaireData.QuestionnaireDataJson({
      questionnaireResponses: this.questionaireResponses,
    });

    this.questionaireResponses[this.punteroTest] =
      QuestionnaireResponse.QuestionnaireResponseJson({
        subtestId: this.subtest.subtestId,
        elapsedTime: 0,
        maxTime: 0,
        remaningTime: 0,
        responseItems: formattedSelectedResponse,
      });

    this.questionaire = ResponseAll.resposeJson({
      testId: this.testStructures[this.punteroTestStructure]?.test_id,
      questionaireResponses: this.questionaireResponses,
      entryLastPoint: this.entryLastPoint,
    });

    if (this.test.subtests?.length > 1) {
      const qResponses = [];
      qResponses.push(this.questionaireResponses[0]);

      result = {
        testId: this.test.test_id,
        questionnaireResponses: qResponses,
        entryLastPoint: this.entryLastPoint,
      };
    } else {
      const qResponses = this.questionaireResponses;
      qResponses[0].responseItems = formattedSelectedResponse;

      result = {
        testId: this.testStructures[this.punteroTestStructure]?.test_id,
        questionnaireResponses: qResponses,
        entryLastPoint: this.entryLastPoint,
      };
    }
    return result;
  }

  // ENVIA LAS RESPUESTAS A LA API
  saveAllResponse(option: number): void {

    // establece un entrylastpoint si es el guardado definitivo
    if (option === 1) {
      this.generateEntryLastPoint(this.getLastItem(), this.indexPage, true);
    }

    // Save responses
    this.dataSvc
      .saveQuestionnareResponses(this.setQuestionnaireJsonBeforeSave(), this.qCode)
      .toPromise()
      .then((data: ResponseAll) => {
        // do nothing
    });

    // guardado y finalización del test
    if (option === 1) {
      this.finished = true;
      this.testSrv.currentTestFinished = true;
      if (this.currentTest.subtests.length > 1) {
        if (this.checkIfCanSaveTestWithSubtests()) {
          setTimeout(() => {
            this.confirmationService.confirm({
              message: this.translate.instant('TEST.MSG-FINISHED-SUBTEST'),
              header: '',
              icon: 'pi pi-exclamation-triangle',
              acceptLabel: this.translate.instant('TEST.BTN-FINISH'),
              acceptIcon: '-',
              rejectLabel: this.translate.instant('TEST.BTN-CANCEL'),
              rejectIcon: '-',
              accept: () => {
                this.dataSvc.sendDataAndCloseQuestionnaire(this.setQuestionnaireJsonBeforeSave(), this.qCode);
                this.router.navigate(['list']);
              },
              reject: () => {
                // alert('Ha cancelado')
              },
            });
          }, 1000);
        } else {
          // fuerza la recarga de los status de los tests cuando vuelva a la lista
          this.testSrv.forceListReload = true;
          this.router.navigate(['list']);
        }
      } else {
        this.dataSvc.sendDataAndCloseQuestionnaire(this.setQuestionnaireJsonBeforeSave(), this.qCode);
        this.router.navigate(['list']);
      }
    }
  }

  checkIfCanSaveTestWithSubtests(): boolean {
    let result = true;

    // TO-DO
    if (this.entryLastPoint !== undefined) {
      // comprueba si el resto de subtests tienen datos o están completados
      const numberOfCompletedSubtests = this.entryLastPoint.filter(x => x.qCode === this.qCode
        && x.subtestId !== this.subTest.subtestId && x.finished).length;

      if (numberOfCompletedSubtests < this.test.subtests.length - 1) {
        result = false;
      }
    } else {
      result = false;
    }

    return result;
  }

  // comprobacion antes de enviar
  beforeSend(): void {
    let countResp = 0;
    if (this.indexNoResp.length > 0) {
      this.indexNoResp.forEach((element, index) => {
        // si hay una sin contestar compruebo
        if (this.selectedResponse[element].responseValue == null) {
          if (this.questions[element].mandatory) {
            countResp++;
            // entran las preguntas que estan sin contestar y estan en mandatory y no se envia muestra aviso
            // this.noSend = true;
          }
        }
      });
      // compruebo si las no respondidas excede el limite de no respondidas
      if (
        this.indexNoResp.length - countResp >
        this.subtestRules?.maxNotAnswered
      ) {
        // this.noSend = true;
      }
    } else {
      // this.noSend = false;
    }
  }

  /*CONFIRM DIALOG */
  displayTimeoutMessage(): void {
    // set test as finalized in service
    this.testSrv.currentTestFinished = true;

    // indica que ha finalizado el tiempo
    this.timeout = true;

    // fuerza la recarga de los status de los tests cuando vuelva a la lista
    this.testSrv.forceListReload = true;

    this.confirmationService.confirm({
      message: this.translate.instant('TEST.MSG-TIMEOUT'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translate.instant('TEST.BTN-ACCEPT'),
      acceptIcon: '-',
      rejectVisible: false,
      accept: () => {
        // comprueba si cumple las condiciones para ser finalizado
        this.checkIfQuestionnaireShouldBeCompleted();
        this.router.navigate(['list']);
      },
      reject: () => {
        // comprueba si cumple las condiciones para ser finalizado
        this.checkIfQuestionnaireShouldBeCompleted();
        this.router.navigate(['list']);
      },
    });
  }

  // Mostrar aviso
  showError(): void {
    this.toastSrv.showToastError(this.translate.instant('TEST.MSG-MANDATORY'));
  }

  showNoSend(text: string): void {
    this.toastSrv.showToastError(text);
  }

  //#region Keyboard control

  private KEY_CODES = {
    RIGHT_ARROW: 'ArrowRight',
    LEFT_ARROW: 'ArrowLeft',
  };
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    switch (event.key) {
      case this.KEY_CODES.LEFT_ARROW:
        this.previousQuestion();
        break;
      case this.KEY_CODES.RIGHT_ARROW:
        if (this.indexPage < this.maxIndexPage) {
          this.nextQuestion();
        }
        break;
      default:
        this.checkKeyboardResponse(event.key);
        break;
    }
  }

  private checkKeyboardResponse(nameKey) {
    // Validate that nameKey corresponds to a valid Response
    let responseOption = this.showQuestionsByIndex(this.indexPage);
    if (responseOption.length === 1 && responseOption[0].responseOptions.options.length >= nameKey && nameKey > 0) {
      this.selectedResponse[responseOption[0].position].responseValue = responseOption[0].responseOptions.options[nameKey - 1].value;
      this.onClick(responseOption[0]);
    }
  }
  //#endregion

  generateEntryLastPoint(lastItem, lastPage, finished): void {
    // elimina el la referencia qCode <-> subtestId del array
    if (this.entryLastPoint) {
      this.entryLastPoint = this.entryLastPoint.filter(x => x.qCode === this.qCode && (x.subtestId !== this.subTest.subtestId));
    } else {
      this.entryLastPoint = [];
    }

    // añade la referencia con los nuevos datos
    this.entryLastPoint.push({
      lastItem,
      lastPage,
      qCode: this.qCode,
      subtestId: this.subTest.subtestId,
      customId: this.subTest.customId,
      finished,
      countdown: this.difference
    });
  }

  getLastItem(): any{
    try{
      const aux = this.entryLastPoint.filter(x => x.qCode !== this.qCode && (x.subtestId !== this.subTest.subtestId));
      if ( aux.length > 0) {
        return aux[0].lastItem;
      } else {
        return 1;
      }
    } catch (ex) {
      return 1;
    }
  }

  showCloseMessage(): void {
    if (!this.isRecoverableSession) {
      this.confirmationService.confirm({
        message: this.translate.instant('TEST.MSG-DEACTIVATE-2'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translate.instant('TEST.BTN-ACCEPT'),
        acceptIcon: '-', // caracter no válido para quitar los iconos
        rejectLabel: this.translate.instant('TEST.BTN-CANCEL'),
        rejectIcon: '-',
        rejectVisible: true,
        accept: () => {
          // cambia el valor, para que el guard deje cambiar la ruta
          // this.isRecoverableSession = true;
          this.checkIfQuestionnaireShouldBeCompleted();
          this.router.navigate(['list']);
        },
        reject: () => {},
      });
    }
  }

  checkIfQuestionnaireShouldBeCompleted(): void {
    // Si el test es de tipo recoverableSession o ha finalizado el tiempo permitodo
    if (!this.subtestRules?.recoverableSession || this.timeout ) {

      // establece el last point como finalizado
      this.generateEntryLastPoint(this.getLastItem(), this.indexPage, true);
      // comprueba si el test tiene multiples subtests, y finaliza el test completo si es el último
      if (this.checkIfCanSaveTestWithSubtests()){
        this.dataSvc.sendDataAndCloseQuestionnaire(this.setQuestionnaireJsonBeforeSave(), this.qCode);
      } else {
        this.dataSvc
          .saveQuestionnareResponses(this.setQuestionnaireJsonBeforeSave(), this.qCode)
          .toPromise()
          .then((data: ResponseAll) => {
            // do nothing
        });
      }
    // Si se debe contabilizar el tiempo del test, se almacena el estado del mismo al salir siempre que no esté finalizado
    } else if (this.subtestRules?.maxTime && !this.finished) {

      this.generateEntryLastPoint(1, this.indexPage, false);

      this.dataSvc.saveQuestionnareResponses(this.setQuestionnaireJsonBeforeSave(), this.qCode)
        .toPromise()
        .then((data: ResponseAll) => {});
    }
  }

  /**
   * si se encuentran todas las preguntas respondidas y la página actual no es la última, muestra la opción de finalizar test
   */
  checkIfIsTheLastNonResponsedQuestion(): void {
    if (this.selectedResponse.filter(x => x.responseValue === null).length <= 0 && this.indexPage < this.maxIndexPage
    && !this.finishCancel) {
      this.checkIfCanSave();
    }
  }

  /**
   * Devuelve verdadero si todas las preguntas obligatorias han sido contestadas, falso en caso contrario.
   */
  checkIfMandatoryAreAnswered(): boolean{
    if (this.indexNoResp.filter(x => x.mandatory).length > 0) {
      return false;
    }
    return true;
  }

  checkResponseClickedStatus() {
    if(!this.subtestRules.canGoForward){
      //First get all items in page
      let items = this.showQuestionsByIndex(this.indexPage);

      //The multicheck should be identified since 1 item filled out of 5 could be valid but
      //showQuestionsByIndex is not differenciating between multichoice and individual items
      let multiChoiceParents = items.filter(x => x.responseOptions?.type === "multipleChoiceParent");
      //Every multichoice must satisfy its own min and max condition to reach a valid filling state
      if(multiChoiceParents?.length>0){
        let validState=true
        for(let i = 0; i < multiChoiceParents.length; i++){
          let checkValidFillingState=this.checkMinMaxCheckbox(multiChoiceParents[i]);
          if(checkValidFillingState===0){
            validState=false
            break
          }
        }

        if(!validState){
          this.responseClicked = false;
          return;
        }
        //Once checked that the multichoices are properly selected,
        //delete from the array to allow individual checks

        let multiChoiceParentsIds=multiChoiceParents.map((x)=>x.itemId)

        items = items.filter((item)=>{
          let itemId = item.multioptionParent ? item.multioptionParent : item.itemId;
          return !multiChoiceParentsIds.includes(itemId)
        })
      }

      //Now, continue with individual items

      let itemIds = items.map(x=> x.itemId);
      let filteredItems=this.selectedResponse.filter(x => itemIds.includes(x.itemId) &&
      (x.responseValue === undefined || x.responseValue === null || x.responseValue === false))
      if(filteredItems.length > 0) {
        // resetea el indicador de respuesta pulsada, ya que existen algunas preguntas sin responder
        this.responseClicked = false;
      } else {
        // las preguntas de la página están respondidas, activa el botón para continuar
        this.responseClicked = true;
      }
    }
  }

  // Show or hide scroll down button
  onResized($event) {
    let heightDiff = 0;
    if(window.innerWidth < 467) { // Small screen
      heightDiff = 210;
    } else if (window.innerWidth < 960) { // Medium screen
      heightDiff = 240;
    } else {
      heightDiff = 200; // Large screen
    }


    if((window.innerHeight-heightDiff) < $event.newRect.height) {
      this.showScroll = true;
    } else {
      this.showScroll = false;
    }
  }

  scrollToBottom(){
    window.scrollTo({ top: window.scrollY + 50, behavior: 'smooth' })
  }

  setSpeedDialButton(){
    this.speedDialItems = [
      {
          icon: 'pi pi-search-plus',
          command: () => {
            this.sizeFont('+')
          }
      },
      {
          icon: 'pi pi-search-minus',
          command: () => {
            this.sizeFont('-')
          }
      },
      {
          icon: 'pi pi-question-circle',
          command: () => {
            this.showPositionDialog('top-right');
          }
      }
    ];
  }

  /**
   * Observe changes at view level
   */
  ngDoCheck() {
    if(this.itemsAlign === ItemsAlignString.centerLeft) {
      this.getMinimumLeftSpace();
    }
  }

  /**
   * Set left space for centered alignment
   */
  getMinimumLeftSpace() {
    this.minLeftSpace = Infinity;

    // Get all response containers
    document.querySelectorAll('.response-content').forEach(_container => {
      // Get response response box
      let responses = _container.querySelector('.responses');
      // Get difference between box and left border
      let result = Math.ceil(responses.getBoundingClientRect().left - _container.getBoundingClientRect().left);
      if(result < this.minLeftSpace) {
        this.minLeftSpace = result;
      }
    });

    // Set style with vanilla js
    document.querySelectorAll('.response-content').forEach(_container => {
      let response = _container.querySelector('.responses') as HTMLElement;
      response.style.marginLeft = this.minLeftSpace + 'px'
      response.style.marginRight = 'auto';
    });
  }

  ngOnDestroy() {
    if (this.interval !== undefined) {
      clearInterval(this.interval);
    }

    this.checkIfQuestionnaireShouldBeCompleted();

    // fuerza la recarga de los status de los tests cuando vuelva a la lista
    this.testSrv.forceListReload = true;

    this.colorSrv.setDefaultBackground();
    this.colorSrv.showFooter();

    this.subs.unsubscribe();
  }
}
