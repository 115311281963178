import { from } from "rxjs";
import {QuestionnaireResponse} from './questionnaireResponse';
import {entryLastPoint} from './entryLastPoint';
export class ResponseAll {
    static resposeJson(obj: Object){
        return new ResponseAll(
            obj['testId'],
            obj['question'],
            obj['entryLastPoint']
        );
    }
    
    constructor(
    public  testId:  string,
    public  questionnaireResponse:  QuestionnaireResponse,
    public  entryLastPoint: entryLastPoint
    ){

    }

    
}