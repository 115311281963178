import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear: any;
  currentAppVersion = environment.appVersion;

  constructor(public translate: TranslateService) {
    let languages = ['en', 'it', 'es', 'tr', 'bg', 'ru', 'hu', 'ua'];

    translate.addLangs(languages);

    const browserLang = translate.getBrowserLang();
    const reg = new RegExp(languages.join('|'))
    translate.use(browserLang.match(reg) ? browserLang : 'en');
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

}
