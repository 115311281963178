<!-- Spinner -->
<div class="spinner p-d-flex p-jc-center p-ai-center">
  <p-progressSpinner [style]="{width: '20wv', height: '20vh'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
</div>

<div *ngIf="loaded">
  <form ngNoForm method="POST" name="hiddenform" action="{{mbtiData.actionUrl}}">
    <input type="hidden" name="bearer_token" value="{{mbtiData.bearerToken}}" />
    <input type="hidden" name="resource_url" value="{{mbtiData.resourceUrl}}" />
    <input id="btnsubmit" #btnsubmit type="submit" value="Submit" />
  </form>
</div>
