export class TestRules {
    static TestRulesJson(obj: Object){
        
        return new TestRules(
            obj['subtestOrder'],
            obj['autostart'],
            obj['autosend'],
            obj['autosave'],
            obj['saveBy'],
            obj['instructions'],
        );
    }
    constructor(
    public subtestOrder:    number[],
    public autostart: boolean,
    public autosend: boolean,
    public autosave: boolean,
    public saveBy: string,
    public instructions: string,
    
    ){

    }


}