export class ResponseItem {
    static ResponseItemsJson(obj: Object){

        return new ResponseItem(
            obj['itemId'],
            obj['elapsedTime'],
            obj['responseType'],
            obj['responseValue'],
         
        );
    }
    
    constructor(
    public   itemId:      number,
    public elapsedTime:   string,
    public responseType:  string,
    public responseValue: number,
   ){

    }

    
}