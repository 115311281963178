export class SubtestRules{
    static SubtestRulestJson(obj: Object){
        return new SubtestRules(
            obj['itemsPerScreen'],
            obj['maxTime'],
            obj['skipItems'],
            obj['canGoBack'],
            obj['canGoForward'],
            obj['maxNotAnswered'],
            obj['recoverableSession'],
            obj['resetAnswers'],
            obj['instructions'],
            obj['mandatory'],
            obj['showQuestionNumber'],
            obj['itemsAlign']
        );
    }

    constructor(
      public itemsPerScreen: number,
      public maxTime: null,
      public skipItems: boolean,
      public canGoBack: boolean,
      public canGoForward: boolean,
      public maxNotAnswered: number,
      public recoverableSession: boolean,
      public resetAnswers: boolean,
      public instructions: string,
      public mandatory: boolean,
      public showQuestionNumber: boolean,
      public itemsAlign: boolean
    ){

    }


}
