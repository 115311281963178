<!-- Spinner -->
<div *ngIf="!loaded" class="spinner p-d-flex p-jc-center p-ai-center">
  <p-progressSpinner [style]="{width: '20wv', height: '20vh'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
</div>

<div *ngIf="loaded">
  <!--TESTLIST-->
  <div *ngIf="!subtest" class="test">

      <h1 *ngIf="completado" class="title1">{{'LIST.LIST-COMPLETE'|translate}}</h1>

      <div *ngFor="let test of testStructures, index as i" class="test-row shadow-3 my-3">
        <div class="p-d-flex">
          <div class="flex justify-content-between align-items-center gap-3 align-self-start">
            <img class="test-row-icon" src="assets/images/mini-logo.png">

            <p class="test-row-title">
              {{test.testTitle}}
              <span *ngIf="test?.subtests && test?.subtests[0]?.subtestRules.maxTime" class="time flex justify-content-start align-items-center gap-2">
                <i class="pi pi-clock"></i>
                <p>{{test.subtests[0].subtestRules.maxTime + ' ' +('LIST.TIME-APROX' | translate)}}</p>
              </span>

              <span *ngIf="evaluatedPersonToShow" class="evaluated-person">
                <p *ngFor="let person of evaluatedPersonToShow" class="evaluated-name my-0"> {{'LIST.EVALUATED-PERSON'|translate}}{{person.nameToShow}}</p>
              </span>
            </p>
          </div>
        </div>

          <div class="test-row-status-btn">
            <!--Estas son todas las opciones: 'completed' | 'assigned' | 'in-progress'-->
            <div class="test-row-status">
              <custom-label *ngIf="test.status == 20" [labelType]="'assigned'"></custom-label>
              <custom-label *ngIf="test.status == 21" [labelType]="'in-progress'"></custom-label>
              <custom-label *ngIf="test.status > 21" [labelType]="'completed'"></custom-label>
            </div>


            <div *ngIf="!test.external" class="test-row-btn">
              <button *ngIf="test.status < 22" (click)="iniciarTest(i)" class="btn-primary" [disabled]="test?.disabled">
                {{ test.status == 20 ? ('LIST.BTN-START'|translate) : test.status == 21 ? ('LIST.BTN-CONTINUE'|translate) : '' }}
              </button>
            </div>

            <div *ngIf="test.external == true" class="test-row-btn">
              <button
                *ngIf="test.status < 22"
                (click)="initExternalTest(i)"
                class="btn-primary"
                [disabled]="test?.disabled || test.loading"
                label="{{ test.status == 20 ? ('LIST.BTN-START'|translate) : test.status == 21 ? ('LIST.BTN-CONTINUE'|translate) : '' }}"
                pButton
                icon="{{ test.loading ? 'pi pi-spin pi-spinner' : 'pi pi-sign-out'}}"
                iconPos="right"
                pTooltip="{{'LIST.EXTERNAL-TEST'|translate}}"
                tooltipPosition="top">
              </button>
            </div>
          </div>
      </div>
  </div>

  <!--SUBTESTLIST-->

  <div *ngIf="subtest" class="test">

      <h1 *ngIf="completado" class="text-center">{{'LIST.LIST-SUB-COMPLETE'|translate}}</h1>

      <h1 *ngIf="!completado" class="text-center">{{'LIST.LIST-SUBTEST'|translate}}</h1>

      <div *ngFor="let subtest of subTestsStructures, index as i" class="test-row shadow-3 my-3">
        <div class="flex justify-content-between align-items-center gap-3 align-self-start">
          <img class="test-row-icon" src="assets/images/mini-logo.png">

          <p class="test-row-title">
            {{subtest.subtestTitle}}
            <span *ngIf="subtest.subtestRules.maxTime" class="time flex justify-content-start align-items-center gap-2">
              <i class="pi pi-clock"></i>
              <p>{{subtest.subtestRules.maxTime + ' ' +('LIST.TIME-APROX' | translate)}}</p>
            </span>
          </p>
        </div>

        <div class="test-row-status-btn">
          <!--Estas son todas las opciones: 'completed' | 'assigned' | 'in-progress'-->
          <div class="test-row-status">
            <custom-label *ngIf="subtest.status == 20" [labelType]="'assigned'"></custom-label>
            <custom-label *ngIf="subtest.status == 21" [labelType]="'in-progress'"></custom-label>
            <custom-label *ngIf="subtest.status > 21" [labelType]="'completed'"></custom-label>
          </div>

          <div class="test-row-btn">
            <button *ngIf="subtest.status < 22" (click)="iniciarSubTest(i)" class="btn-primary" [disabled]="subtest?.disabled || subtestOrder[0] == subtest.subtestId">
              {{ subtest.status == 20 ? ('LIST.BTN-START'|translate) : subtest.status == 21 ? ('LIST.BTN-CONTINUE'|translate) : '' }}
            </button>
          </div>
        </div>
    </div>

    <div class="text-center my-3">
      <button (click)="goBack()" class="btn-primary">{{'LIST.BTN-BACK'|translate}}</button>
    </div>

  </div>
</div>
