import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../services/data.service';
import { LocalService } from '../services/local.service';
import { TestService } from '../services/test.service';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {

  constructor(private router: Router, private localSrv: LocalService){}

  canActivate(): boolean {
    let qCode: any = this.localSrv.getJsonValue('currentQcode');

    if(!qCode){
      qCode = this.localSrv.getJsonValue('lastQCode')
    }

    if(qCode){
      return true;
    } else {
      this.router.navigate(['completed', { errorCode: '5006'}]);
      return false;
    }
  }
}
