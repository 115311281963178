import { ResponseOption } from "./responseOption";

export class Questions{

static questionJson(obj: Object){

    return new Questions(
        obj['itemId'],
        obj['question'],
        obj['parsedQuestion'],
        obj['responseOptions'],
        obj['mandatory'],
        obj['instructions?'],
        obj['item_group'],
        obj['grup_content'],
    );
}

    constructor(
    public itemId:          number,
    public question:        string,
    public parsedQuestion: string,
    public responseOptions: ResponseOption,
    public mandatory:       boolean,
    public instructions:   null,
    public item_group:     number | null,
    public grup_content:   null | string,
    ){}

    get allQuestions(){
        return this.question;
    }

}
