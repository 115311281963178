import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'custom-label',
  templateUrl: './custom-label.component.html',
  styleUrls: ['./custom-label.component.scss']
})
export class CustomLabelComponent implements OnInit {

  @Input() labelType: 'completed' | 'assigned' | 'in-progress' | 'planned' | 'finished' | 'executed' = 'completed';

  constructor() { }

  ngOnInit(): void {
  }

}
