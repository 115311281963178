import { Component,ElementRef,OnDestroy,OnInit, QueryList, ViewChild, ViewEncapsulation } from '@angular/core';
import { ReactiveFormConfig, RxwebValidators } from '@rxweb/reactive-form-validators';
import { DataService } from 'src/app/services/data.service';
import { QuestionService } from 'src/app/services/question.service';
import { Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { QuestionBase } from 'src/models/test/questionBase';
import { TestI } from 'src/models/test';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';
import { TestService } from 'src/app/services/test.service';
import { v4 as uuidv4 } from 'uuid';
import {distance} from  'fastest-levenshtein'
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http'
import { ColorChangeService } from 'src/app/services/color-change.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [QuestionService]
})
export class FormComponent implements OnInit, OnDestroy {

  @ViewChild('inpt') inputs: QueryList<ElementRef>;

  questions: Observable<QuestionBase<any>[]>;

  public test: boolean = false;
  val: number;
  public selectedGender: any = null;
  public genders: any = [{ name: 'Hombre', code: 'male' }, { name: 'Mujer', code: 'female' }];
  public data: TestI[] = [];
  public form_template: any = [];
  public label: string = 'Nombre';
  public profileData: any;
  public myFormGroup: UntypedFormGroup;

  public sharedData: any;
  public sharedSubs: any;
  public sharedQcode: any;
  public qCode: any;
  public loaded = false;
  private urlApi = environment.apiUrl;

  subs = new SubSink();

  nonGroupedFields = [];
  groupedFields = [];
  dynamicOptions = [];
  groupCommonOptions = [];
  profileDataGroups = [];
  groupsCollection = [];

  constructor(
    public dataSvc: DataService,
    private testSrv: TestService,
    private router: Router,
    private translate: TranslateService,
    private http: HttpClient,
    private colorSrv: ColorChangeService
  ) { }

  ngOnInit(): void {

    // Recibe si se trata de un test
    this.test = this.dataSvc.getTipoTest(); // TO-DO: Que utilidad tiene esto?

    this.setValidationMessages();

    // Almacena el qCode del cuestionario
    this.subs.sink = this.dataSvc.sharedCurrentQcode.subscribe((data) => {
      this.qCode = data;
    });

    this.subs.sink = this.dataSvc.sharedCurrentStatistics.subscribe(statistics => {

      // Comprueba si hay datos de formulario (en caso de refresh)
      this.subs.sink = this.dataSvc.sharedCurrentFormItems.subscribe(res => {

      // Comprueba si el test tiene datos de formulario
      if (res && res.length > 0) {

        // Establece las labels definitivas
        this.setFieldLabels(res)

        // Para almacenar el objeto con el profile data del questionario
        let aux = {};
        this.form_template = res; // .filter((obj) => (aux[obj.name] ? false : (aux[obj.name] = true)));

        // Salta las preguntas de tipo psyQuestion
        this.form_template = this.form_template.filter(x => !x?.psyQuestion);


        // Salta las preguntas para estadísticas si procede
        if (!statistics) {

          this.form_template = this.form_template.filter(x => (x.mandatoryForScoring || x.mandatoryForReporting)
           || !x.mandatoryForStatistics);
        }

        // set option labels as string to avoid empty dropdown value
        this.form_template.filter(x => x.type === 'select').forEach(x => {
          x.options.forEach(z => {
            z.label = z.label + '';

          });
        });

        // renombrar los inputs con mismo nombre para evitar duplicidades
        this.form_template.forEach(item => {
          if (this.form_template.filter(x => x.name === item.name).length > 1) {
            item.originalName = item.name;
            item.name = `${item.name}_${new Date().getTime()}`;
          }
        });

        // organiza el formulario en función del orden, en caso de que lo tenga
        if (this.form_template.filter(x => x?.order).length > 0) {
          this.form_template.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
        }

        if (this.form_template.length > 0) {
          // Genera el Formulario
          let group = {};
          this.form_template.forEach((input_template) => {
            let validations = [];
            if (!input_template?.psyQuestion) {
              if (input_template?.validation && input_template?.validation.length > 0) {
                if (input_template.validation[0].type === 'required') {
                  validations.push(RxwebValidators.required());
                }
              }
              if (input_template.type.toLowerCase() === 'number') {
                if (!input_template.validation[0].min && !input_template.validation[0].max) {
                  // normal validation, no negative
                  validations.push(RxwebValidators.minNumber({value: 0}));
                } else {
                  if (input_template.validation[0].min) {
                    validations.push(RxwebValidators.minNumber({value: +input_template.validation[0].min}));
                  }
                  if (input_template.validation[0].max) {
                    validations.push(RxwebValidators.maxNumber({value: +input_template.validation[0].max}));
                  }
                }
              }
              group[input_template.name] = new UntypedFormControl('', validations);
            }
          });

          this.getProfileDataGroups();

          this.nonGroupedFields = this.getNonGroupedFields();

          this.nonGroupedFields = this.nonGroupedFields.filter(x => !x.hidden)

          this.groupedFields.forEach((group:any)=>{
            group.items = group.items.filter(x => !x.hidden)
          })

          // comprueba si los select del grupo tienen factores comunes
          this.setCommonSelectOptions();

          // asigna los fields al formgroup
          this.myFormGroup = new UntypedFormGroup(group);
        } else {
          this.router.navigate(['instructions']);
        }

        this.subs.sink = this.dataSvc.sharedCurrentPersonal.subscribe((data) => {
          if (data !== null) {
            // si ya tiene datos, pasa directamente a las instrucciones
            this.router.navigate(['instructions']);
          } else { // Get data from api
            this.dataSvc.getQuestionProfile(this.qCode).subscribe(
              res => {
                let formData = res.data.profileData;
                if (formData !== null) {
                  this.router.navigate(['instructions']);
                }
              },
              err => {
                // do nothing
              }
            );
          }
        });
      } else {
        this.router.navigate(['instructions']);
      }
    });
    });
    this.testSrv.setShowHelp(true);
  }

  ngAfterViewInit(): void {
    this.colorSrv.setIceBlueBackground();
  }

  setValidationMessages(): void {
    this.subs.sink = this.translate.get('random.key').subscribe(() => {
      ReactiveFormConfig.set(
        { validationMessage :
          {
            minNumber: this.translate.instant('FORM.MIN'),
            maxNumber: this.translate.instant('FORM.MAX')
          }
        });
    });
  }

  fillForm(data){
    this.form_template.forEach((item) => {
      this.myFormGroup.controls[item.name].patchValue(data[item.name]);
      this.myFormGroup.controls[item.name].markAsTouched();
    });

    // Forzar que muestra la etiqueta
    setTimeout( () => {
      try{
        this.inputs[0].nativeElement.click();
      } catch (ex){
        // Do nothing
      }
    }, 100 );
  }

  onSubmit(): void {
    // Si el formulario es valido creo objeto y lo envio
    if (this.myFormGroup.valid) {
      // objeto para localStorage
      this.dataSvc.setSharedCurrentPersonal(this.myFormGroup.value);

      this.subs.sink = this.testSrv.sharedcurrentQuestionnaires.subscribe(res => {
        res.questionnaires.forEach(questionnaire => {
          // objeto para enviar por cuestionario
          let personalData = {};
          this.form_template.filter(x => x.questionnaires.includes(questionnaire.questionnaireId)).forEach((item) => {
            let auxItemName: string;
            if (item.originalName) {
              auxItemName = item.originalName;
            } else {
              auxItemName = item.name;
            }

            if (item.type === 'select' && this.myFormGroup.controls[item.name].value?.value) {
              personalData[auxItemName] = this.myFormGroup.controls[item.name].value?.value;
            } else if (this.myFormGroup.controls[item.name].value) {
              personalData[auxItemName] = this.myFormGroup.controls[item.name].value;
            }
          });

          if (JSON.stringify(personalData) !== '{}') {
            this.dataSvc.saveProfileData(personalData, questionnaire.questionnaireId).toPromise().then((data: any) => {
              // this.router.navigate(['instructions']);
            });
          }
        });

        this.router.navigate(['instructions']);
      });
    } else {
      this.myFormGroup.markAllAsTouched();
    }
  }

  getNonGroupedFields(): any {
    return this.form_template.filter(x => !x.group);
  }

  getGroupedFields(): any {
    const groups = [...new Set(this.form_template.map(x => x.group))];

    groups.forEach(group => {
      if (group) {
        let commonOptions;
        if (this.profileDataGroups){
          commonOptions =  this.profileDataGroups?.find(x => x.name === group)?.commonOptions ? this.profileDataGroups.find(x => x.name === group).commonOptions : false
        }
        this.groupsCollection.push({
          name: group,
          items: this.form_template.filter(x => x.group === group),
          label: this.form_template.filter(x => x.group === group)[0].groupDescription,
          commonOptions: commonOptions ? commonOptions  : false
        });
      }
    });
    // Diferenciamos dos tipos de grupos:
    // - groupsCollection: serán los grupos a mostrar las preguntas de profileData
    // - this.groupCommonOptions: dentro de los grupos de groupsCollection, los que compartan opciones
    this.groupCommonOptions = this.groupsCollection?.filter(x => x.commonOptions === true);

    // Eliminamos duplicados para ambos objetos de grupos
    // (al realizar esta función tantas veces como quest tenga un testtaker, se duplican los grupos dentro del objeto)
    this.groupCommonOptions = [...new Map(this.groupCommonOptions.map((x)=> [x["name"], x])).values()];
    this.groupsCollection = [...new Map(this.groupsCollection.map((x)=> [x["name"], x])).values()];

    // Se comprueban las opciones comunnes de this.groupCommonOptions
    this.setCommonSelectOptions();
    this.loaded = true;
    return this.groupsCollection;
  }

  setCommonSelectOptions(): void{
    // questo serve solo per gestire la scelta dei sei aggettivi del magellano...
    this.groupCommonOptions.forEach(group => {
      // clono la lista de items cuyo type sea select
      const items = [...group.items.filter(x => x.type === 'select')];

      // ordeno la lista de items para buscar repetidos
      items.sort((a, b) => (a.name > b.name) ? 1 : -1);

      // extraigo los que sean repetidos
      const equalInputs = [];
      if (items.length > 1){
        for (let i = 0; i < items.length; i++) {
          if (i === items.length - 1){
            if (this.compareOptions(items[i], items[i-1])) {
              equalInputs.push(items[i]);
            }
          } else {
            if (this.compareOptions(items[i + 1], items[i])) {
              equalInputs.push(items[i]);
            }
          }
        }
      }
      // Si existen campos con options repetidas, genero un objeto con un id único e indico como opciones
      // las mismas del primer objeto que se repite, puesto que en todas es igual, además añado la propiedad
      // inactive que será la que indique si el campo puede volver a ser usado por el resto o no.
      //
      // La finalidad de esto es que los campos que tengan opciones similares compartirán las mismas opciones
      // entre ellos.
      if (equalInputs.length > 0){
        const optionId = uuidv4();
        // Genero la opción compartida
        this.dynamicOptions.push({
          id: optionId,
          options: equalInputs[0].options.map(x => ({...x, inactive: false}))
        });
        // Hago referencia a la opción compartida en los campos originales.
        equalInputs.forEach(x => {
          group.items.filter(z => z.name === x.name)[0]['dynamicOption'] = optionId;
        });
      }
    });
  }

  compareOptions(field1, field2): boolean{
    if (JSON.stringify(field1.options) === JSON.stringify(field2.options)
      && distance(field1.name, field2.name) < 2
      && field1.name.substring(0, 3) === field2.name.substring(0, 3)) {
      return true;
    }
    return false;
  }

  /**
   * Actualiza el estado de las opciones del dropdown compartido, para activar/desactivar
   * @param $dynamicOptionId id referencia del elemento
   */
  dynamicOptionChange($dynamicOptionId): void{
    this.groupCommonOptions.forEach(group => {
      const selectedValues = [];
      const aux = this.dynamicOptions.filter(x => x.id === $dynamicOptionId)[0];
      group.items.filter(x => x.dynamicOption === aux.id).forEach(item => {
        try{
          selectedValues.push(this.myFormGroup.get(item.name).value);
        } catch (ex) {}
      });
      aux.options.forEach(option => {
        if (selectedValues.includes(option.value)){
          option.inactive = true;
        } else {
          option.inactive = false;
        }
      });
    });
  }

  setFieldLabels(fields) {
    this.testSrv.sharedcurrentQuestionnaires.subscribe(questionnaires => {
      // Get all questionnaires length
      const questionnairesLength = questionnaires.questionnaires.length;
      // If there are more than one questionnaire
      if(questionnairesLength > 1) {
        // Avoid the fields with the same number of questionnaires (no specific test label nedeed)
        fields.filter(field => field.questionnaires.length < questionnairesLength).forEach(field => {
          // Add the specific name of the test in the field
          const testNames = " ["+Object.keys(field.tests).join('] [')+"]"
          field.label = !field.label.includes(testNames) ? `${field.label} ${testNames}` : field.label;
        });
      }
    })
  }

  /**
   * Elabora un array donde se encuentran los grupos de preguntas del profileData de todos los test de un testtaker
   */
  getProfileDataGroups() {
    this.subs.sink = this.testSrv.sharedcurrentQuestionnaires.subscribe(res => {
      res.questionnaires.forEach( _questionnaire => {
         this.http.get<any>(this.urlApi + '/test_structure/' + _questionnaire.questionnaireId).subscribe((structure) => {
            //Guardamos los grupos de preguntas del profileData
            if(structure.data.profileData?.groups){
              structure.data.profileData.groups.forEach(_group => {
                this.profileDataGroups.push(_group)
              })
            }
            this.groupedFields = this.getGroupedFields();
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.colorSrv.setDefaultBackground();
    this.subs.unsubscribe();
  }

}
