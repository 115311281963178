import { ViewportScroller } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelpService } from 'src/app/services/help.service';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';
import { ColorChangeService } from 'src/app/services/color-change.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})

export class HelpComponent implements OnInit, OnDestroy {

    activeState: boolean[] = [];

    subs = new SubSink();

    constructor(
      private viewPortScroller: ViewportScroller,
      private helpSrv: HelpService,
      private location: Location,
      private colorSrv: ColorChangeService
    ) { }

    ngOnInit(): void {
      this.subs.sink = this.helpSrv.sharedSelectedHelp.subscribe(res => {
        if (res !== null) {
          this.toggle(res.position);
          setTimeout( () => {
            this.anchorTo(res.key);
          }, 500 );
          this.helpSrv.setSelectedHelp(null);
        }
      });
      this.colorSrv.setIceBlueBackground();
    }

    goBack() {
      this.location.back()
    }

    anchorTo(id: string): void{
      console.log(id);
      const elementXPosition = document.getElementById(id).offsetLeft;
      const elementYPosition = document.getElementById(id).offsetTop - 110; // Fot scroll the height of the navbar

      this.viewPortScroller.scrollToPosition([elementXPosition, elementYPosition]);
    }

    toggle(i: number): void {
      if (!this.activeState[i]) {
        this.activeState[i] = true;
      }
      // this.activeState[index] = !this.activeState[index];
    }

    ngOnDestroy(): void {
      this.colorSrv.setDefaultBackground();
      this.subs.unsubscribe();
    }
}


