<div [ngSwitch]="inputForm.type" class="mb-1">
  <div *ngSwitchCase="'singleLine'">
    <span>
      <label class="label"><span *ngIf="inputForm.validation[0]?.type==='required'"
          class="required-input">*</span>{{inputForm.label}}</label>
      <input #inpt type="text" class="custom-input-text" id="{{inputForm.name}}" formControlName="{{inputForm.name}}"
        pInputText>
    </span>
    <span class="form-error">
      <div *ngIf="myFormGroup.get(inputForm.name)?.errors && myFormGroup.get(inputForm.name).touched">
        {{inputForm.validation[0].alert.length <= 0 ? ('FORM.MSG-REQUIRED' | translate) :
          inputForm.validation[0].alert}} </div>
          <div *ngIf="!myFormGroup.get(inputForm.name)?.errors || !myFormGroup.get(inputForm.name).touched">
            &nbsp;
          </div>
    </span>
  </div>

  <div *ngSwitchCase="'number'">
    <span>
      <label class="label">
        <span *ngIf="inputForm.validation[0]?.type==='required'" class="required-input">*</span>
        {{inputForm.label}}
        <span *ngIf="inputForm.validation[0].min" class="sublabel">[Min. {{inputForm.validation[0].min}}]</span>
        <span *ngIf="inputForm.validation[0].max" class="sublabel"> [Max. {{inputForm.validation[0].max}}]</span>
      </label>
      <input #inpt type="number" class="custom-input-text" id="{{inputForm.name}}" formControlName="{{inputForm.name}}"
        pInputText>
    </span>
    <span class="form-error">
      <div *ngIf="myFormGroup.get(inputForm.name)?.errors && myFormGroup.get(inputForm.name).touched">
        {{myFormGroup.get(inputForm.name).errors.minNumber ? myFormGroup.get(inputForm.name).errors.minNumber?.message +
          inputForm.validation[0].min : ''}}

        {{myFormGroup.get(inputForm.name).errors.maxNumber ? myFormGroup.get(inputForm.name).errors.maxNumber?.message +
          inputForm.validation[0].max : ''}}

        {{!myFormGroup.get(inputForm.name).errors.maxNumber && !myFormGroup.get(inputForm.name).errors.minNumber
          ? inputForm.validation[0].alert : ''}}
      </div>
      <div *ngIf="!myFormGroup.get(inputForm.name)?.errors || !myFormGroup.get(inputForm.name).touched">
        &nbsp;
      </div>
    </span>
  </div>

  <div *ngSwitchCase="'select'">
    <span [ngClass]="'regular-label'">
      <label for="float-input" class="label"><span *ngIf="inputForm.validation[0]?.type==='required'"
          class="required-input">*</span>{{inputForm.label}}</label>
      <div *ngIf="inputForm.group && inputForm.dynamicOption; else nonGroup">
        <p-dropdown #inpt
            class="custom-dropdown"
            formControlName="{{inputForm.name}}"
            filterBy="name"
            [showClear]="true"
            inputId="{{inputForm.name}}"
            placeholder="&nbsp;"
            [options]="getDynamicOption(inputForm.dynamicOption)"

            optionDisabled="inactive"
            (onChange)="optionChanged(inputForm.dynamicOption)">
        </p-dropdown>
    </div>
    <ng-template #nonGroup>
        <p-dropdown
            [options]="inputForm.options"
            formControlName="{{inputForm.name}}"
            class="custom-dropdown"
            placeholder="&nbsp;"
            optionLabel="label"
            optionValue="value"
            [showClear]="true">
        </p-dropdown>
    </ng-template>
    </span>
    <span class="form-error">
      <div *ngIf="myFormGroup.get(inputForm.name)?.errors && myFormGroup.get(inputForm.name).touched">
        {{inputForm.validation[0].alert.length <= 0 ? ('FORM.MSG-REQUIRED' | translate) :
          inputForm.validation[0].alert}} </div>
          <div *ngIf="!myFormGroup.get(inputForm.name)?.errors || !myFormGroup.get(inputForm.name).touched">
            &nbsp;
          </div>
    </span>
  </div>
</div>
