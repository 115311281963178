export const environment = {
  production: false,
  crypto: false,
  secretKey: 'L4NC3REV0VI',
  appVersion: require('../../package.json').version + '-dev',
  security: true,
  apiUrl: 'https://assessment-dev.giuntipsy.com/back',
  googleAnalyticsTag: 'G-7FX9T98C4X',

  //If true, only the page_view of pages defined in ganalyticsService will be sent to google analytics
  filterUrlsGAnalytics: false,

  cookieBotId: 'bbd30405-644c-41b5-a6ec-40e5eb6f6fd2',
};
