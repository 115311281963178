import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class InputComponent implements OnInit {

  @Input() inputForm: any;
  @Input() myFormGroup: any;
  @Input() dynamicOptions: any;
  @Output() optionChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  getDynamicOption(dynamicOptionId): any{
    if (this.dynamicOptions){
      let groupedOptions = this.dynamicOptions.find(x => x.id === dynamicOptionId);
    return groupedOptions.options
    }
  }

  optionChanged(dynamicOptionId): void{
    this.optionChange.emit(dynamicOptionId);
  }
}
