<p-toast position="top-center" key="toast-sucess" [preventOpenDuplicates]="true"></p-toast>

<p-toast position="top-center" key="toast-error">
    <ng-template let-message pTemplate="message">
        <div class="p-d-flex p-ai-center p-jc-center toast-content">
            <p><i class="pi pi-info-circle"></i> <span class="p-mx-2">{{message.detail}}</span></p>
            <button type="button" class="btn-toast-error pointer p-mx-3" (click)="close()">{{message.summary}}</button>
        </div>
    </ng-template>
</p-toast>
