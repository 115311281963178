import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-toast-content',
  templateUrl: './toast-content.component.html',
  styleUrls: ['./toast-content.component.scss']
})
export class ToastContentComponent implements OnInit {

  constructor(private messageSrv: MessageService) { }

  ngOnInit(): void {
  }

  close(): void {
    this.messageSrv.clear('toast-error');
  }
}
