import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { TestService } from 'src/app/services/test.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit, OnDestroy {
  public instruction: string;
  public subtest: boolean = false;
  public onlytest: boolean = true;
  public punteroStructure: number=0;

  // si es true se solicita las respuestas
  public getResponses: any[] = [];

  subs = new SubSink();

  public currentTest: any;
  public subtestTitle: string;

  constructor(
    public dataSvc: DataService,
    private testSrv: TestService,
    private router: Router) { }

  ngOnInit(): void {
    this.subs.sink = this.testSrv.sharedCurrentTest.subscribe(res => {
      if (res !== null) {
        this.currentTest = res;

        this.subs.sink = this.testSrv.sharedCurrentSelectedSubtest.subscribe(subtestId => {

          this.subs.sink = this.testSrv.sharedCurrentStructures.subscribe(structures => {

            const sub = this.testSrv.getTestOfTestStructureBasedOnId(structures, subtestId);

            // instrucciones del parenttest en caso de que sea un test con más de 1 subtest
            if (this.currentTest !== null && !sub) {
              this.instruction = this.currentTest.testRules.instructions;
            } else {
              // si es un subtest muestra de un grupo de más de 1 muestra las instrucciones del subtest
              if (this.currentTest.subtests.length > 1) {
                this.instruction = sub.subtestRules.instructions;
                this.subtestTitle = sub.subtestTitle;
              } else { // muestra las instrucciones del test general en caso de que sea un simple test
                this.instruction = this.currentTest.testRules.instructions;
              }
            }

            // si las instrucciones están vaciás pasa directamente al test
            if (!this.instruction){
              this.goTest();
            }
          });
        });
      }
    });
    this.testSrv.setShowHelp(true);
  }

  goTest(): void {
    // Si se trata de un parenttest con un solo subtest
    if (this.currentTest && (this.currentTest?.subtests?.length === 0 || !this.currentTest?.subtests)) {
      this.initTest();
    } else { // si tiene varios subtest
      this.subs.sink = this.testSrv.sharedCurrentSelectedSubtest.subscribe(subtestId => {
        // si no se encuentra ningún subtest del parent seleccionado
        if (subtestId === null) {
          this.router.navigate(['list']);
        } // si se encuentra al menos un subtest seleccionado
        else if (this.currentTest.subtests.filter(x => x.subtestId === subtestId).length > 0) {
          this.initTest();
        }
      });
    }
  }

  initTest(): void {
    this.subs.sink = this.testSrv.sharedcurrentQuestionnaires.subscribe(question => {
      const questionnaire = question.questionnaires.filter(x => x.questionnaireId === this.currentTest.qCode)[0];
      if (questionnaire.status === '20' || questionnaire.status === 20) {
        this.dataSvc.startQuestionnaire(this.currentTest.qCode).toPromise().then((data: any) => {
            console.log("initTest")
            console.log(this.currentTest?.entryUrl)
           if (this.currentTest.entryUrl.includes('volassessment')) {
             window.open(`${this.currentTest.entryUrl}`, '_self');
            }
          else if(!this.currentTest.entryUrl.includes('assessment')){
             window.open(`${this.currentTest.entryUrl}`, '_self');
          }else {
            this.router.navigate(['test']);            
          }
        });
      } else {
          console.log("initTest")
          console.log(this.currentTest?.entryUrl)
          if (this.currentTest.entryUrl.includes('volassessment')) {
              window.open(`${this.currentTest.entryUrl}`, '_self');
          }
        else if(!this.currentTest.entryUrl.includes('assessment')){
          window.open(`${this.currentTest.entryUrl}`, '_self');
        }else {
          this.router.navigate(['test']);
        }
      }
    });
  }

  goToHome(): void {
    this.testSrv.sharedcurrentQuestionnaires.subscribe(question => {
      if (question !== null) {
        const qCode = question.questionnaires[0].questionnaireId;
        this.router.navigate(['home', qCode]);
      } else {
        this.router.navigate(['home']);
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


}
