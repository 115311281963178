import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ToastService } from 'src/app/services/toast.service';
import { Routes, RouterModule, ActivatedRoute, Router } from '@angular/router';
import { isNgTemplate } from '@angular/compiler';
import { TestI } from 'src/models/test';
import { SubtestRules } from 'src/models/test/subtestRules';
import { TestRules } from 'src/models/test/testrules';
import { TestService } from 'src/app/services/test.service';
import { SubSink } from 'subsink';
import { LocalService } from 'src/app/services/local.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [ToastService]
})
export class HomeComponent implements OnInit, OnDestroy {
  public data: TestI[] = [];
  public test: boolean = false;
  public showTitle: boolean = true;
  public testRules: any = [];
  public privacies: boolean[] = [];
  public formItems: any = [];
  public statisticsCheck: boolean = false;

  // para almacenar subtest
  public subtestRules: SubtestRules;

  // contenedor de todos los cuestionarios del usuario
  public questionnaires: any = [];

  private privacyChecked: boolean = false;
  private testStructuresAux = [];

  subs = new SubSink();

  public testStructures: any = [];
  constructor(public dataSvc: DataService,
              private testSrv: TestService,
              private route: ActivatedRoute,
              private router: Router,
              private localSrv: LocalService) {
              }

  ngOnInit(): void {
    // captura el qcode de la ruta
    let qCode =  this.route.snapshot.paramMap.get('id');
    if(!qCode) qCode = this.route.snapshot.queryParamMap.get('id');
    if(!qCode) this.localSrv.getJsonValue('currentQcode'); // after login the code is stored in the secure storage...
    if(!qCode){
      this.router.navigate(['err', { errorMessage: 'ERROR.MISSING-REQUIRED-PARAM'  }], {skipLocationChange: true});
      return;
    }

    // check if a previous lunch already exsist in this browser for the same code (reload?)
    const currentQCode = this.localSrv.getJsonValue('lastQCode');
    if (!!currentQCode && qCode !== currentQCode ) {
      //console.error("qCode is <> than currentQCode!",{"currentQCode":currentQCode,"qCode":qCode});
      localStorage.clear();
      this.testSrv.clearSubjects();
      this.dataSvc.clearSubjects();
      // devuelvo el valor de privacy despues del limpiado completo
      this.dataSvc.setSharedCurrentStatistics(this.privacyChecked);
    }

    this.localSrv.setJsonValue('lastQCode',qCode);
    this.privacyChecked=this.localSrv.getJsonValue('privacyChecked')||false;
    // envio el codigo del cuestionario para las llamadas a la api
    this.subs.sink = this.dataSvc.sharedCurrentQcode.subscribe(res => {
      if (res === null && qCode){
        this.dataSvc.setSharedCurrentQcode(qCode);
      }
    });
    this.dataSvc.getConfig(qCode).then((sessData) => { this.sessionDataLoaded(sessData); } );

    //Extract the queryParam toCallUrl
    if(this.route.snapshot.queryParams.toCallUrl){
      this.dataSvc.setSharedCurrentToCalUrl(this.route.snapshot.queryParams.toCallUrl);
    }
  }

  sessionDataLoaded(sessData){
    this.questionnaires = sessData;
    this.testStructuresAux = [];
    this.formItems = [];
    this.questionnaires.forEach((quest, index) => {
      if (quest?.profileData) {
        if (quest?.profileData.privacy) {
           // como cada test tiene su privacy hay que guardarlo en array con el mismo indice que su test
          this.privacies[index] = true;
        } else {
          this.privacies[index] = false;
        }
        // Buscamos el profile data para el formulario
        if (quest?.profileData.formItems) this.loadAndPrepareQuestionnairePersonalData(quest);
      }
      // para saber si tiene uno o mas test
      if (this.questionnaires.length > 1) this.test = true;
      this.dataSvc.setTipoTest(this.test);
      // obtener todos los structures
      this.dataSvc.getStructure(quest.questionnaireId, quest.external).then(tStruct => {
        this.testStructLoaded(tStruct,quest.id,quest.questionnaireId);
      });
    });
    this.ifReadyNext();
  }

  loadAndPrepareQuestionnairePersonalData(quest){
    if (!quest || !quest.profileData || !quest.profileData.formItems || quest.profileData.formItems.length < 1) return;
    quest.profileData.formItems.forEach((item) => {
      this.paItemDecorate(item,quest.profileData);
      // adding the personal data of this questionnaire to the shared pool
      let prevItems=this.formItems.filter(x => this.paItemsAreTheSame(item, x));
      if(prevItems.length==0){// new item, decorate it with simple properties, than add
        item.questionnaires = [quest.questionnaireId];
        item.tests={};
        item.tests[quest.testTitle]=1;
        this.formItems.push(item);
      }else{ // at least one item with the same name exist already
        prevItems.forEach(pI => {
          pI.questionnaires.push(quest.questionnaireId);
          if(pI.tests[quest.testTitle]) pI.tests[quest.testTitle]++;
          else pI.tests[quest.testTitle]=1;
          // anyone is mandatory => both are, but "ForStatistics" that is the opposite! (no idea what it is)
          if (item.validation[0].type === 'required') pI.validation[0].type = 'required';
          if(item.mandatoryForScoring) pI.mandatoryForScoring=true;
          if(item.mandatoryForReporting) pI.mandatoryForReporting=true;
          pI.mandatoryForStatistics = pI.mandatoryForStatistics && item.mandatoryForStatistics;
        });
      }
    });
    this.dataSvc.setSharedFormItems(this.formItems);
  }

  testStructLoaded(testStructure,qId,qCode):void {
    let data = testStructure ? testStructure['data']: {};
    data.qId = qId;
    data.qCode = qCode;
    this.testStructuresAux.push(data);
    // Almacena la estructura completa del test
    this.testSrv.setCurrentStructures(this.testStructuresAux);
    this.ifReadyNext();
  }

  ifReadyNext(){
    if(this.questionnaires.length==this.testStructuresAux.length){
      this.testSrv.setShowHelp(true);
      // if the user has previously accepted privacy or the customer has skip_privacy enabled, go to the list
      this.subs.sink = this.dataSvc.sharedCurrentConfig.subscribe(data => {
        if (data) {

          const skipPrivacy = Number(data.customer?.skip_privacy) === 1 ? true : false;

          if (this.privacyChecked) {
            // Go to test list
            this.router.navigate(['list']);
          } else if(skipPrivacy) {
            // Activate index spinner
            this.dataSvc.setSharedShowIndexSpinner(true);

            // Wait 3 seconds
            setTimeout(() => {
              // Desactivate index spinner
              this.dataSvc.setSharedShowIndexSpinner(false);

              // Go to test list
              this.router.navigate(['list']);
            }, 2500);
          } else {
            // Go to privacy page
            this.router.navigate(['privacy']);
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    if(this.subs)this.subs.unsubscribe();
    this.subs.unsubscribe();
  }

  /** Some decoration to the original item to make it easier to use */
  paItemDecorate(item,profileData){
    item.mandatoryForScoring = profileData.mandatoryForScoring && profileData.mandatoryForScoring.includes(item.name);
    item.mandatoryForReporting = profileData.mandatoryForReporting && profileData.mandatoryForReporting.includes(item.name);
    item.mandatoryForStatistics = profileData.mandatoryForStatistics && profileData.mandatoryForStatistics.includes(item.name);
    if(item.group){
      const gr= profileData.groups.find(x => x.name === item.group);
      if(gr && gr.label)item.groupDescription=gr.label;
    }
  }
  /** returns true if the two items (=personal data) have the same name, type and options */
  paItemsAreTheSame(a,b):boolean{
    if(!a) return !b;
    if(a.name!=b.name) return false;
    if(a.type!=a.type) return false;
    if(a.options){
      if(!b.options || b.options.length!=a.options.length) return false;
      const set=new Set();
      a.options.forEach( o => set.add(o.value));
      b.options.forEach( o => {if(!set.has(o.value)) return false;});
    }
    return true;
  }

}
