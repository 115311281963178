<h1 class="text-center mb-7">{{'INSTRUCTIONS.TITLE'|translate}} <span *ngIf="subtestTitle"> - {{subtestTitle}}</span></h1>
<div class="instruction" [innerHTML]="instruction"></div>
<div class="instructions-btn my-5">
  <button
    *ngIf="!currentTest.testRules.autostart"
    pButton
    type="button"
    class="btn-secondary p-mx-2"
    label="{{'LIST.BTN-BACK'|translate}}"
    (click)="goToHome()">
  </button>
  <button
    pButton
    type="button"
    class="btn-secondary p-mx-2"
    label="{{'INSTRUCTIONS.BTN-OK'|translate}}"
    (click)="goTest()">
  </button>
</div>
