import { Injectable } from '@angular/core';
import { SHA256, AES, enc } from 'node_modules/crypto-js';
import { environment } from 'src/environments/environment';

const SecureStorage = require('secure-web-storage');
const SECRET_KEY = environment.secretKey;

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
      if(environment.crypto){
        key = SHA256(key, [SECRET_KEY]);
      }
      return key.toString();
    },
    encrypt: function encrypt(data) {
      if(environment.crypto){
        data = AES.encrypt(data, SECRET_KEY);

        data = data.toString();
      }
      return data;
    },
    decrypt: function decrypt(data) {
      if(environment.crypto){
        data = AES.decrypt(data, SECRET_KEY);

        data = data.toString(enc.Utf8);
      }
      return data;
    }
  });
}
