<header>
    <div class="content flex justify-content-between align-items-center">
      <img [src]="logoUrl ? logoUrl : 'assets/images/logo_header.svg'"  class="pointer img" (click)="goToHome()">

      <div class="profile-text flex justify-content-end align-items-center">
        <span class="text-right flex justify-content-end align-items-center">
          <button
            pButton
            *ngIf="showHelp"
            type="button"
            icon="pi pi-question-circle"
            class="p-button-rounded"
            [routerLink]="'help'">
          </button>
          <span>{{userName}}</span>
        </span>
        <div class="profile-avatar">
          <p-avatar icon="pi pi-user" size="xlarge" shape="circle"></p-avatar>
        </div>
      </div>
    </div>

  <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog> <!--TO-DO: Revisar si esto tiene sentido-->
</header>
