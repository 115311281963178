export class Subtest {
    static SubtestJson(obj: Object){
        return new Subtest(
            obj['subtestId'],
            obj['subtestTitle'],
        );
    }
    constructor(
    public subtestId:    string,
    public subtestTitle: string,
    
    ){

    }

    getSubtestTitle(){
        return this.subtestTitle;
    }
}