import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { resolve } from 'dns';
import { ResponseAll } from 'src/models/test/responseAll';
import { Questions } from 'src/models/test/questions';
import { TestRules } from 'src/models/test/testrules';
import { SubtestRules } from 'src/models/test/subtestRules';
import { TestService } from './test.service';
import { Router } from '@angular/router';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  @Output() indexListTest: EventEmitter<any> = new EventEmitter();

  private currentConfig = new BehaviorSubject<any>(null);
  sharedCurrentConfig = this.currentConfig.asObservable();

  private currentFormItems = new BehaviorSubject<any>(null);
  sharedCurrentFormItems = this.currentFormItems.asObservable();

  private currentPersonal = new BehaviorSubject<any>(null);
  sharedCurrentPersonal = this.currentPersonal.asObservable();

  private currentTest = new BehaviorSubject<any>(null);
  sharedCurrentTest = this.currentTest.asObservable();

  private currentTestStructure = new BehaviorSubject<any>(null);
  sharedCurrentTestStructure = this.currentTestStructure.asObservable();

  private currentQcode = new BehaviorSubject<any>(null);
  sharedCurrentQcode = this.currentQcode.asObservable();

  private currentStatistics = new BehaviorSubject<boolean>(null);
  sharedCurrentStatistics = this.currentStatistics.asObservable();

  private currentToCallUrl = new BehaviorSubject<string>(null);
  sharedCurrentToCallUrl = this.currentToCallUrl.asObservable();

  private currentShowIndexSpinner = new BehaviorSubject<boolean>(false);
  sharedCurrentShowIndexSpinner = this.currentShowIndexSpinner.asObservable();

  public punteroTest: number;
  public punteroStructure: number;
  public response: ResponseAll[] = [];
  public test: boolean = false;
  public instructions: string = "";
  public subtestRules: any = [];
  public testRules: TestRules[] = [];
  public formItems: any = [];
  public recoverableSession: boolean;
  //para saber si estoy haciendo el test
  public testActive: string;
  //Indice del subtest
  public indexSubTest: number = 0;

  public testTitle: any=[];

  //requisitos para enviar o mostrar mensajes de intento de envio del test
  public noRequirements: boolean;
  public allOk: boolean;
  public minResp: boolean;

  //enseñar lista subtest o test
  public subtest: boolean = false;
  public onlytest: boolean = true;

  //identificador del questionario para hacer peticiones a la api
  public questionnaireCode: string;


  //si es true se solicita las respuestas
  public getResponses: any[] = [];

  //contenedor de todos los cuestionarios del usuario
  public questionnaires: any = [];


  //contenedor de todos los test del usuario
  public testStructures: any = [];

  private questions: Questions[] = [];
  private urlApi = environment.apiUrl;

  constructor(private http: HttpClient,
              private testSrv: TestService,
              private router: Router,
              private localSrv: LocalService) {
    const storedConfig = this.localSrv.getJsonValue('storedConfig');
    const formItems = this.localSrv.getJsonValue('formItems');
    const currentPersonal = this.localSrv.getJsonValue('personalData');
    const currentTest = this.localSrv.getJsonValue('currentTest');
    const currentTestStructure = this.localSrv.getJsonValue('currentTestStructure');
    const currentQcode = this.localSrv.getJsonValue('currentQcode');
    const currentStatistics = this.localSrv.getJsonValue('currentStatistics');
    const currentToCallUrl = this.localSrv.getJsonValue('toCallUrl');

    if (storedConfig) {
      try {
        this.setSharedConfig(storedConfig);
      } catch (ex) {}
    }

    if (formItems) {
      try {
        this.setSharedFormItems(formItems);
      } catch (ex) {}
    }

    if (currentPersonal) {
      try {
        this.setSharedCurrentPersonal(currentPersonal);
      } catch (ex) {}
    }

    if (currentTest) {
      try {
        this.setSharedCurrentTest(currentTest);
      } catch (ex) {}
    }

    if (currentTestStructure) {
      try {
        this.setSharedCurrentTestStructure(currentTestStructure);
      } catch (ex) {}
    }

    if (currentQcode) {
      try {
        this.setSharedCurrentQcode(currentQcode);
      } catch (ex) {}
    }

    if (currentStatistics) {
      try {
        this.setSharedCurrentStatistics(currentStatistics);
      } catch (ex) {}
    }

    if (currentToCallUrl) {
      try {
        this.setSharedCurrentToCalUrl(currentToCallUrl);
      } catch (ex) {}
    }
  }

  /*LLAMADAS A LA API*/
  // coger información del test
  getConfig(IdQuestionari: string) {
    return new Promise(resolve => {

      this.http.get<any>(this.urlApi + '/getConfig/' + IdQuestionari).subscribe((allConfig) => {

        this.setSharedConfig(allConfig.data)
        this.setQuestionnaireCode(IdQuestionari);
        //meto todos los test
        if (this.questionnaires.length < allConfig.data.questionnaires.length) {
          //meto todos los test
          allConfig.data.questionnaires.forEach(questionnaire => {
            this.questionnaires.push(questionnaire);
            this.testTitle.push(questionnaire.testTitle);
          });

          this.testSrv.setCurrentQuestionnaires(allConfig.data);

          //comprobamos los config de los test para ver si es un test para empezar o ya empezado (filling = 21  o assigned = 20)
          this.questionnaires.forEach((test, index) => {
            //Creamos objeto que guarda el id del questionario y si necesita llamada a la responses
            this.getResponses[index] = {
              idQuestionnaire: test.questionnaireId,
              getResponses: null,
              status: test.status,
              disabled: false
            }
            if (test.status == '20' || test.status == '21' ) {
              if (test.status == '21'){
              this.getResponses[index].getResponses = true;
              }else{
                this.getResponses[index].getResponses = false;
              }

            } else {
              this.getResponses[index].getResponses = false;
              this.getResponses[index].disabled = true;
            }
          });
        }
        resolve(this.questionnaires);
      },
      (err) => {
        console.log(err);
        this.router.navigate(['completed', { errorCode: err.error.code}]);
      });
    });
  }

  getConfigReloaded(qCode): Observable<any>{
    return this.http.get<any>(this.urlApi + '/getConfig/' + qCode);
  }

  // Para pedir el la estructure del test
  getStructure(Id: string, external: boolean) {

    return new Promise(resolve => {

      this.http.get<any>(this.urlApi + '/test_structure/' + Id).subscribe((structure) => {

        if (this.testStructures.length < this.questionnaires.length) {
          //meto todos los test
          this.testStructures.push(structure.data)

          //recorremos el array del structure y si hay alguno que necesite las preguntes entra y lo hace (porque es un test empezado)
          this.testStructures.forEach((test, index) => {

            if(test.subtests) {
              test.subtests.forEach(subtestRules => {
                if (this.getResponses[index].getResponses == true) {

                  //comprobamos que es true o no existe resetAnswers
                  if (subtestRules.subtestRules.resetAnswers == undefined || subtestRules.subtestRules.resetAnswers == true) {
                    //llamamos para que nos de las responses del test
                    this.getQuestionnaireResponses(this.getResponses[index].idQuestionnaire).then(resp => {
                    })
                  }
                }
              });
            }
          });

          // Set default testRules, empty subtests and external flag for a external test
          if(external){
            structure.data.testRules = {
              autosave: false,
              autosend: false,
              autostart: false,
              itemLabel: 'Default',
              instructions: '',
              subtestOrder: [1],
            };
            structure.data.subtests = [];
            structure.data.profileData = {};
            structure.data.external = true;
          }

          //Guardamos los testRules de cada test con el mismo indice
          this.setTestRules(TestRules.TestRulesJson(structure.data.testRules))
        }
        resolve(structure)
      })
    })

  }

  // Enviar a la api las respuestas del questionario ya sea por item o page
  saveQuestionnareResponses(responseAll: any, idQuestionnaire?): Observable<any> {
    return this.http.patch<any>(this.urlApi + '/questionnaire/' + idQuestionnaire + '/responses', responseAll);
  }

  // metodo post para enviar las respuestas mediante la api y ACABAR el test
  addAllresponse(responseAll: any, qCode?:any): Observable<any> {
    if(qCode)
      return this.http.patch<any>(this.urlApi + '/questionnaire/' + qCode + '/finish', responseAll);
    else
      return this.http.patch<any>(this.urlApi + '/questionnaire/' + this.questionnaireCode + '/finish', responseAll);

  }

  // Para enviar el formulario
  saveProfileData(profileData: any, qCode?:any): Observable<any> {
    if (qCode) {
      return this.http.put<any>(this.urlApi + '/questionnaire/' + qCode + '/profile', profileData);
    } else {
      return this.http.put<any>(this.urlApi + '/questionnaire/' + this.questionnaireCode + '/profile', profileData);
    }
  }

  //Para notificar que se empieza el test
  startQuestionnaire(idQuestionnaire) {
    return this.http.patch<any>(this.urlApi + '/questionnaire/' + idQuestionnaire + '/start', "");
  }

  getQuestionnaireResponses(questionnaireCode) {
    return new Promise(resolve => {

      this.http.get<any>(this.urlApi + '/questionnaire/' + questionnaireCode + '/responses').subscribe((responses) => {

        // if( this.testStructures.length<=this.questionnaires.length){
        //meto todos los test
        //Comprobar que recibimos y dependiendo como nos viene la informacion guardarla de una forma u otra

        resolve(responses)
        // }
      })
    })
  }

  getBlindQuestionnaireCode(friendlyId, shortCode): Observable<any>{
    return this.http.get<any>(`${this.urlApi}/bcode/${friendlyId}/${shortCode}`);
  }

  //Getters y setter para mostrar mensajes de envio o salida desde el test a la cabecera
  setNoRequirements(require: boolean) {
    this.noRequirements = require;
  }
  getNoRequirements(): boolean {
    return this.noRequirements;
  }

  setAllOk(all: boolean) {
    this.allOk = all;
  }

  getAllOk(): boolean {
    return this.allOk;
  }

  setMinResp(minR: boolean) {
    this.minResp = minR;
  }

  getMinResp(): boolean {
    return this.minResp;
  }

  //para coger y enviar el codigo del test
  setQuestionnaireCode(code: string) {
    this.questionnaireCode = code;
  }

  //condiciones para mostrar lista subtest
  setOnlytest(only: boolean) {
    this.onlytest = only;
  }
  getOnlytest(): boolean {
    return this.onlytest;
  }

  setSubtest(subt: boolean) {
    this.subtest = subt;
  }

  getSubtest(): boolean {
    return this.subtest;
  }

  //indice de los id de questionario y si necesita respuestas para cargar
  getResponse(): any {
    return this.getResponses
  }

  //Te devuelve todos los testStructure
  getTestStructures() {
    return this.testStructures;
  }

  getQuestionnaires() {
    return this.questionnaires;
  }

  //regla del subtest de recoverableSession
  setRecoverableSession(recover: boolean) {
    this.recoverableSession = recover;
  }
  getRecoverableSession(): boolean {
    return this.recoverableSession;
  }

  //indexdel Subtest
  setIndexSubTest(index: number) {
    this.indexSubTest = index;
  }
  getIndexSubTest(): number {
    return this.indexSubTest;
  }

  //ruta del test activo
  setTestActive(url: string) {
    this.testActive = url;
  }

  getTestActive(): string {
    return this.testActive;
  }

  setPuntero(puntero: number) {
    this.punteroTest = puntero;
  }
  getPuntero(): number {

    return this.punteroTest;
  }

  // Puntero del Structure donde estan todos los test
  setPunteroStructure(puntero: number) {
    this.punteroStructure = puntero;
  }

  getPunteroStructure(): number {

    return this.punteroStructure;
  }

  getTipoTest(): boolean {
    return this.test
  }

  setTipoTest(test: boolean) {
    this.test = test
  }

  setIntruction(instruc: string) {
    this.instructions = instruc;
  }

  getIntruction(): string {
    //Aqui sería con el puntero del structure (version api)
    return this.testRules[this.punteroStructure].instructions;
    //return this.testRules[this.punteroTest]?.instructions;
    //return  this.instructions;
  }

  /*ForItems*/
  getFormItems(): any[] {
    return this.formItems;
  }

  /*Subtest Rules */
  getSubtestRules(): any[] {
    return this.subtestRules;
  }
  setSubtestRules(subtestRules: SubtestRules) {
    this.subtestRules = subtestRules;
  }

  /*Test Rules */
  setTestRules(testRuls: TestRules) {
    this.testRules.push(testRuls);
  }

  getTestRules(): TestRules[] {
    return this.testRules;
  }

  setSharedConfig(config: any): void {
    this.localSrv.setJsonValue('storedConfig', config);
    this.currentConfig.next(config);
  }

  setSharedFormItems(items: any): void {
    this.localSrv.setJsonValue('formItems', items);
    this.currentFormItems.next(items);
  }

  setSharedCurrentPersonal(personalData: any): void {
    this.localSrv.setJsonValue('personalData', personalData);
    this.currentPersonal.next(personalData);
  }
  setSharedCurrentTest(currentTest: any): void {
    this.localSrv.setJsonValue('currentTest', currentTest);
    this.currentTest.next(currentTest);
  }

  setSharedCurrentTestStructure(currentTestStructure: any): void {
    this.localSrv.setJsonValue('currentTestStructure', currentTestStructure);
    this.currentTestStructure.next(currentTestStructure);
  }

  setSharedCurrentQcode(currentQcode: any): void {
    this.localSrv.setJsonValue('currentQcode', currentQcode);
    this.currentQcode.next(currentQcode);
  }

  setSharedCurrentStatistics(statistics: any): void {
    this.localSrv.setJsonValue('currentStatistics', statistics);
    this.currentStatistics.next(statistics);
  }

  setSharedCurrentToCalUrl(toCallUrl: string): void {
    this.localSrv.setJsonValue('toCallUrl', toCallUrl);
    this.currentToCallUrl.next(toCallUrl);
  }

  setSharedShowIndexSpinner(value: boolean): void {
    this.currentShowIndexSpinner.next(value);
  }

  getQuestionAnswered(idQuestionnaire: any): any{
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.urlApi + '/questionnaire/' + idQuestionnaire + '/responses').subscribe(
        (data) => {
          this.setSharedCurrentTest(data);
          resolve(data.data);
        },
        (error) => {
          reject(error);
        });
    });
  }

  getQuestionProfile(idQuestionnaire: any): any{
    return this.http.get<any>(this.urlApi + '/questionnaire/' + idQuestionnaire + '/profile');
  }

  getQuestionnaireExternalData(idQuestionnaire: any): any{
    return this.http.get<any>(this.urlApi + '/questionnaire/' + idQuestionnaire + '/external');
  }

  getQuestionnaireExternalStatus(idQuestionnaire: any): any{
    return this.http.get<any>(this.urlApi + '/questionnaire/' + idQuestionnaire + '/external/status');
  }

  clearSubjects(){
    this.setSharedConfig(null);
    this.setSharedFormItems(null);
    this.setSharedCurrentPersonal(null);
    this.setSharedCurrentTest(null);
    this.setSharedCurrentTestStructure(null);
    this.setSharedCurrentQcode(null);
    this.setSharedCurrentToCalUrl(null);
  }

  sendDataAndCloseQuestionnaire(questionnaireJson, qCode): void {
    this.addAllresponse(questionnaireJson, qCode).toPromise()
      .then((data: ResponseAll) => {
        this.router.navigate(['list']);
      });
  }

  getCurrentQCode(): string {
    return this.questionnaireCode;
  }

  checkToCallUrl(){
    if(this.currentToCallUrl.value !== null){
      // Activate index spinner
      this.setSharedShowIndexSpinner(true);

      setTimeout(() => {
        // Go to the provided url
        window.open(this.currentToCallUrl.value, '_self');
      }, 5000);
    }
  }
}
