import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReactiveFormConfig, RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { DataService } from 'src/app/services/data.service';
import { ToastService } from 'src/app/services/toast.service';
import { SubSink } from 'subsink';

// Declare bubble animation
const inOutAnimation =  trigger(
  'inOutAnimation',
  [
    transition(
      ':enter',
      [
        style({ opacity: 0 }),
        animate('1s ease-out',
                style({ opacity: 1 }))
      ]
    ),
    transition(
      ':leave',
      [
        style({ opacity: 1 }),
        animate('1s ease-in',
                style({ opacity: 0 }))
      ]
    )
  ]
);

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [inOutAnimation],
  providers: [ToastService],
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  showPassword: Boolean = false;
  loading: Boolean = false;
  isBlocked: Boolean = false;
  subs = new SubSink();

  constructor(
    public router: Router,
    private formBuilder: RxFormBuilder,
    private translate: TranslateService,
    private toastSrv: ToastService,
    private dataSrv: DataService
  ) {}

  ngOnInit(): void {
    localStorage.clear();
    this.setValidationMessages();
    this.generateForm();

    this.subs.sink = this.translate.onLangChange.subscribe(() => {
      this.setValidationMessages();
      this.loginForm.reset();
    });
  }

  setValidationMessages(): void {
    this.subs.sink = this.translate.get("random.key").subscribe(() => {
      ReactiveFormConfig.set({
        validationMessage: {
          required: this.translate.instant("LOGIN.REQUIRED")
        },
      });
    });
  }

  generateForm(): void {
    this.loginForm = this.formBuilder.group({
      shortCode: ["", [RxwebValidators.required()]],
      friendlyId: ["", [RxwebValidators.required()]],
    });
  }

  get shortCode(): any {
    return this.loginForm.get("shortCode");
  }

  get friendlyId(): any {
    return this.loginForm.get("friendlyId");
  }

  login(): void {
    this.loading = true;
    this.subs.sink = this.dataSrv.getBlindQuestionnaireCode(this.friendlyId.value,this.shortCode.value).subscribe(res => {
      this.router.navigate(["home", res.data.qcode]);
      this.toastSrv.showToastSucess(this.translate.instant("LOGIN.API-OK"))
    }, err => {
      if((err.status === 404 || err.status === 400) && err.error.code === 5006){
        this.toastSrv.showToastError(this.translate.instant("LOGIN.API-NO"))
      } else {
        this.toastSrv.showToastError(err.message)
      }
    }).add(() => this.loading = false);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
