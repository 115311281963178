import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api/menuitem';
import { Router } from '@angular/router'
import { DataService } from 'src/app/services/data.service';
import { ConfirmationService } from 'primeng/api';
import { TestService } from 'src/app/services/test.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ConfirmationService]
})
export class HeaderComponent implements OnInit {
  items: MenuItem[];
  isArrowRotate = '';
  userName = '';
  public logoUrl = '';
  public session: boolean;
  public testActive: string;
  public noRequirements: boolean;
  public allOk: boolean;
  public minResp: boolean;
  public userSubs: any;
  public showHelp : boolean;

  constructor(
    public dataSvc: DataService,
    private translate: TranslateService,
    private router: Router,
    private testSrv: TestService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.initComponent();
    });

    this.initComponent();

  }

  initComponent(): void {

    // Aquí debe pillar el nombre del usuario
    this.userSubs = this.dataSvc.sharedCurrentConfig.subscribe(data => {
      if (data !== null) {
        if (data.testtaker.friendlyId) {
          this.userName = data.testtaker.friendlyId;
        }
        if (data.customer && data.customer.logo && data.customer.logo !== ""){
          this.logoUrl=data.customer.logo;
        }
      }
    });

    this.testSrv.helpShowed.subscribe(data => {
      this.showHelp = data;
    });

    this.loadMenu();
  }

  loadMenu(): void {
    this.translate.get('random.key').subscribe(() => {
      this.items = [
        {
          label: this.translate.instant('HELP.HELP').toUpperCase(),
          routerLink: 'help'
        }
      ];
    });
  }

  goToHome(): void {
    if(!this.dataSvc.getCurrentQCode()) return;
    const showOne = 0;
    this.session = this.dataSvc.getRecoverableSession();
    this.testActive = this.dataSvc.getTestActive();
    this.allOk = this.dataSvc.getAllOk();
    this.minResp = this.dataSvc.getMinResp();
    if (this.testActive == "/test") {
      let msg = "Si sale de la prueba, NO podrá continuar posteriormente"
      if (this.session) msg = "Podrá continuar la prueba posteriormente";
      this.confirmationService.confirm({
        message: msg,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'ACEPTAR',
        acceptIcon: '-',
        // rejectVisible: false,
        accept: () => {
          this.router.navigate(['list']);
        },
        reject: () => {
          // nothing to do
        }
      });
    } else {
      this.testSrv.sharedcurrentQuestionnaires.subscribe(question => {
        let qCode = (question!==null)?question.questionnaires[0].questionnaireId:this.dataSvc.getCurrentQCode();
        this.router.navigate(['home', qCode]);
      });
    }
  }

  arrowToBottom(): void {
    this.isArrowRotate = 'rotate';
  }

  arrowToTop(): void {
    this.isArrowRotate = 'no-rotate';
  }
}
