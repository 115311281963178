import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-mbti',
  templateUrl: './mbti.component.html',
  styleUrls: ['./mbti.component.scss']
})
export class MbtiComponent implements OnInit, AfterViewInit {
  mbtiData: any = {};
  loaded = false;
  //In your component class properties
  @ViewChild('btnsubmit', { static: false}) btnsubmit: ElementRef;

  constructor(private route: ActivatedRoute, private dataSrv: DataService){}

  ngOnInit(){

    this.route.queryParamMap.subscribe((params: any) => {
      // Set data
      this.mbtiData.actionUrl = params.params.actionUrl;
      this.mbtiData.resourceUrl = params.params.resourceUrl;
      this.mbtiData.bearerToken = params.params.bearerToken;

      this.loaded = true;
    });
  }

  ngAfterViewInit(): void {
    // Click on submit
    this.btnsubmit.nativeElement.click()
  }
}
