import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNgTemplate } from '@angular/compiler';
import { TestService } from 'src/app/services/test.service';
import { LocalService } from 'src/app/services/local.service';
import { DataService } from 'src/app/services/data.service';
import { DialogModule } from 'primeng/dialog';


@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  public privacycheck: boolean = true;
  public statisticsCheck: boolean = false;

  private privacyChecked;

  public displayLegal: boolean = false;

  constructor( private testSrv: TestService,
              private route: ActivatedRoute,
              private router: Router,
              private localSrv: LocalService,
              private dataSrv: DataService) { }

  ngOnInit(): void {
    this.testSrv.setShowHelp(false);
    if (this.privacyChecked) this.router.navigate(['list']);
  }

  goToNextStep(): void {
    this.testSrv.setShowHelp(false);
    this.dataSrv.setSharedCurrentStatistics(this.statisticsCheck);
    this.router.navigate(['list']);
  }

  showLegal() {
    this.displayLegal = true;
  }
}
