<form [formGroup]="loginForm" class="login-form">
    <h3 class="text-center mb-6">{{'LOGIN.TITLE' | translate}}</h3>

    <span class="p-float-label">
        <input id="shortCode" type="text" pInputText class="custom-input-text" formControlName="shortCode">
        <label for="shortCode">{{'LOGIN.SHORTCODE' | translate}}</label>
        <span *ngIf="shortCode.invalid && shortCode.touched" [@inOutAnimation] class="error-tooltip">
            {{loginForm.controls.shortCode['errorMessage']}}
        </span>
    </span>
    <span class="p-float-label">
    <input id="friendlyId" type="text" pInputText class="custom-input-text" formControlName="friendlyId">
    <label for="friendlyId">{{'LOGIN.FRIENDLYID' | translate}}</label>
    <span *ngIf="friendlyId.invalid && friendlyId.touched" [@inOutAnimation] class="error-tooltip">
        {{loginForm.controls.friendlyId['errorMessage']}}
    </span>
    </span>

    <div class="text-right mt-5">
        <button type="submit" class="btn-primary" pButton label="{{'LOGIN.CONTINUE' | translate}}"
            [disabled]="loginForm.invalid" (click)="login()">
            <p-progressSpinner *ngIf="loading" strokeWidth="4" [style]="{width: '40px', height: '40px'}">
            </p-progressSpinner>
        </button>
    </div>
</form>
