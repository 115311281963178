import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { TestService } from '../services/test.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => Observable<boolean>;
  confirmationService: ConfirmationService;
}

@Injectable({
  providedIn: 'root',
})
export class CanExitFromTestGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private testSrv: TestService, private translate: TranslateService){}

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {

    if (!this.testSrv.isRecoverableSession && !this.testSrv.currentTestFinished) {
      this.testSrv.forceListReload = true;
      return new Observable((observer) => {
        component.confirmationService.confirm({
          message: this.translate.instant('TEST.MSG-DEACTIVATE-2'),
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: this.translate.instant('TEST.BTN-ACCEPT'),
          acceptIcon: '-',
          rejectLabel: this.translate.instant('TEST.BTN-CANCEL'),
          rejectIcon: '-',
          rejectVisible: true,
          accept: () => {
            observer.next(true);
            observer.complete();
          },
          reject: () => {
            observer.next(false);
            observer.complete();
          },
        });
      });
    } else {
      return true;
    }
  }
}
