<!-- Spinner -->
<div *ngIf="!loaded" class="spinner p-d-flex p-jc-center p-ai-center">
  <p-progressSpinner [style]="{width: '20wv', height: '20vh'}" strokeWidth="4" class="custom-spinner" animationDuration="2s"></p-progressSpinner>
</div>

<!-- FORM -->
<div *ngIf="loaded" class="content">
  <h2 class="text-center">{{'FORM.PERSONAL-DATA' | translate}}</h2>
  <form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()" class="form">
    <!--Primero hago que aparezcan los inputs que no pertenecen a ningún grupo-->
    <div class="form-group">
      <div *ngFor="let form_elem of nonGroupedFields">
        <app-input [inputForm]="form_elem" [myFormGroup]="myFormGroup"></app-input>
      </div>
    </div>

    <!--Luego aparecen los inputs que si pertenecen a grupos-->
    <div *ngFor="let group of groupedFields">
      <label>{{group.label}}</label>
      <hr class="group-divisor">
      <div class="form-group mt-4">
        <div *ngFor="let form_elem of group.items">
          <app-input
            [inputForm]="form_elem"
            [myFormGroup]="myFormGroup"
            [dynamicOptions]="dynamicOptions"
            (optionChange)="dynamicOptionChange($event)"></app-input>
        </div>
      </div>
    </div>

    <div class="text-center mb-2">
      <button *ngIf="test" pButton type="Submit" label="{{'FORM.BTN-SUBMIT' | translate}}" class="btn-primary" [disabled]="!myFormGroup.valid"></button>
      <button *ngIf="!test" pButton type="Submit" label="{{'FORM.BTN-SUBMIT' | translate}}" class="btn-primary" [disabled]="!myFormGroup.valid"></button>
    </div>
  </form>
</div>

