import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ListComponent } from './components/list/list.component';
import { LoginComponent } from './components/login/login.component';
import { HelpComponent } from './components/help/help.component';
import { TestComponent } from './components/test/test.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';

import { CommonModule } from '@angular/common';
import { FormComponent } from './components/form/form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrimengModule } from './primeng.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastContentComponent } from './components/toast-content/toast-content.component';
import { InstructionsComponent } from './components/instructions/instructions.component';
import { CustomLabelComponent } from './components/custom-label/custom-label.component';
import { CompletedComponent } from './components/completed/completed.component';
import { AccessGuard } from './guards/access.guard';
import { CanExitFromTestGuard } from './guards/can-exit-from-test.guard';
import { PadNumberPipe } from './pipes/pad-number.pipe';
import { InputComponent } from './components/form/input/input.component';
import { AngularResizeEventModule } from 'angular-resize-event';
import { GAnalyticsService } from './services/ganalytics.service';
import { CookieComponent } from './components/cookie/cookie.component';
import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { CookiebotConfig } from './components/cookie/cookiebot.config';
import { MbtiComponent } from './components/external/mbti/mbti.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'home/:id', component: HomeComponent },
  { path: 'home/blind/login', component: LoginComponent },
  { path: 'test', component: TestComponent, canActivate: [AccessGuard], canDeactivate: [CanExitFromTestGuard]},
  { path: 'form', component: FormComponent, canActivate: [AccessGuard] },
  { path: 'instructions', component: InstructionsComponent, canActivate: [AccessGuard] },
  { path: 'list', component: ListComponent, canActivate: [AccessGuard] },
  { path: 'completed', component: CompletedComponent},
  { path: 'help', component: HelpComponent},
  { path: 'err', component: CompletedComponent},
  { path: 'privacy', component: PrivacyComponent },
  { path: 'cookies', component: CookieComponent },
  { path: 'mbti', component: MbtiComponent },
  { path: '**', component: HomeComponent },

];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PrivacyComponent,
    ListComponent,
    LoginComponent,
    TestComponent,
    FormComponent,
    ToastContentComponent,
    InstructionsComponent,
    CustomLabelComponent,
    CompletedComponent,
    HelpComponent,
    PadNumberPipe,
    InputComponent,
    CookieComponent,
    MbtiComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    BrowserAnimationsModule,
    PrimengModule,
    AngularResizeEventModule,
    NgxCookiebotModule.forRoot(CookiebotConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [GAnalyticsService],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(httpClient: HttpClient): any {
  return new TranslateHttpLoader(httpClient, './assets/i18n/v1/', '.json');
}
