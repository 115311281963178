import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss']
})
export class CompletedComponent implements OnInit {

  errorCode:string;
  errorMessage:string;

  constructor(private route: ActivatedRoute, public dataSrv: DataService) { }

  ngOnInit(): void {
    localStorage.clear();
    this.errorCode = this.route.snapshot.params.errorCode;
    this.errorMessage=this.route.snapshot.params.errorMessage;
    if(this.errorCode=='undefined') this.errorCode=null;
    if(this.errorMessage=='undefined') this.errorMessage=null;
    if(!this.errorCode&&!this.errorMessage) this.errorMessage='ERROR.GENERIC-ERROR';

    // Check if exist toCallUrl
    this.dataSrv.checkToCallUrl();
  }

}
