<footer class="footer">
  <div class="content py-3">
    <div class="footer-address-language flex justify-content-between gap-2">
      <select #langSelect (change)="translate.use(langSelect.value)" class="mx-5">
        <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">
          {{ lang }}</option>
      </select>

      <div class="address">
        <p>© 2003, {{currentYear}}, Giunti Psychometrics S.r.l. - 00421250481
          <br>
          Data Protection Manager (art. 37 of the EU REG 2016/679) Avv. Victoria Parise
          <br>
          <a href="mailto:privacy@giuntipsy.com" class="pointer">privacy@giuntipsy.com</a>
        </p>
      </div>
    </div>

    <div class="footer-logo flex gap-2">
      <div>
        <p>Powered by</p>
      </div>
      <div>
        <img src="assets/images/logo_footer.png">
        <br>
        <span>Assessment v: {{currentAppVersion}}</span>
      </div>
    </div>
  </div>
</footer>
