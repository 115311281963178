import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorChangeService {

  constructor() { }

  setIceBlueBackground() {
    document.querySelector('.content-background-color').classList.add('background-ice-blue');
  }

  setDefaultBackground() {
    document.querySelector('.content-background-color').classList.remove('background-ice-blue');
  }

  showFooter() {
    document.querySelector('.footer').classList.remove('element-hidden');
  }

  hideFooter() {
    document.querySelector('.footer').classList.add('element-hidden');
  }
}
