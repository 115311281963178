import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padNumber'
})
export class PadNumberPipe implements PipeTransform {

  transform(value: unknown, digitsNumber: number): unknown {
    return String(value).padStart(digitsNumber, '0');
  }

}
