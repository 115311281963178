<!--QUESTIONS CONTAINER-->
<div class="test-content-parent" *ngIf="loaded" (resized)="onResized($event)">

    <div #container class="container p-shadow-2" [ngClass]="{'container-500': under500}" *ngIf="reloadAnimation">

      <!--Tools for non mobile screen-->
      <div class="container-tools-lg text-right">
        <div class="inline-flex tools">
          <button (click)="sizeFont('+')" type="button" class="btn-options" [disabled]="!increase">
            <i class="pi pi-search-plus"></i>
          </button>

          <button (click)="sizeFont('-')" type="button" class="btn-options" [disabled]="!decrease">
            <i class="pi pi-search-minus"></i>
          </button>

          <button type="button" (click)="showPositionDialog('top-right')" class="btn-options" label="Show">
            <i class="pi pi-question-circle"></i>
          </button>
        </div>
      </div>


      <!--QUESTIONS-->
      <div [@inOutAnimation] class="questions-container">
            <div *ngFor="let question of showQuestionsByIndex(indexPage)" class="size-{{fontSize}}"
              [ngClass]="[(question.multioptionParent || question.scoringItems) ? 'column-checkbox-' + question.responseOptions.columns : 'question',
                          (question?.question.trim().length === 0 && !question.multioptionParent) ? 'fit-height-empty-question' : '' ]">
                          <!--Si el enunciado de la pregunta está vacío y no es de tipo checkbox, añadir una clase para que el tamaño entre pregunta y pregunta sea mas justo-->

              <!--Enunciado de la pregunta-->
              <span *ngIf="question.multioptionParent==undefined" class="question-statement">
                <span
                  *ngIf="question.mandatory"
                  class="question-title question-mandatory mx-2">*</span>
                <!--Si la pregunta es de tipo checkbox muestro el número de la página, si es una pregunta normal muestro la posición de la misma-->
                <span *ngIf="subtestRules.showQuestionNumber">{{question.scoringItems ? getQuestionsMinMax() : question.position + 1 }}.&nbsp;&nbsp;</span>
                <span
                  id="{{question.itemId}}"
                  class="question-title"
                  [innerHTML]="question?.question">
                </span>
              </span>

              <div *ngIf="question.multioptionParent==undefined && question.scoringItems" class="min-max">
                <span *ngIf="question.minimumOptionsRequired > 0">[Min. {{question.minimumOptionsRequired}}]</span>
                <span *ngIf="question.maximumOptionsRequired > 0">[Max. {{question.maximumOptionsRequired}}]</span>
              </div>

              <div class="response-content response-content-{{questions[0].responseOptions.labelAlign}}"
                    [ngClass]="{'multioption' : question.multioptionParent, 'multioption-first': question.scoringItems, 'align-content-to-center' : !(question.multioptionParent || question.scoringItems) && question.responseOptions.columns <= 1}">

                <div class="responses grid-container-{{question.responseOptions.columns}}"
                      [ngClass]="{'responses-margin-left' : (!question.responseOptions.labelAlign || question.responseOptions.labelAlign === 'right') && (question.multioptionParent || question.scoringItems),
                                  'responses-margin-right' : (question.responseOptions.labelAlign === 'left') && (question.multioptionParent || question.scoringItems),
                                  'responses-label-bottom': question.responseOptions.labelAlign ==='bottom',
                                  'multioption' : question.multioptionParent || question.scoringItems}"
                                  [ngStyle]="(itemsAlign === itemsAlignEnum.left) ?
                                                {'margin-left': '0px', 'margin-right': 'auto'} :
                                                  (itemsAlign === itemsAlignEnum.right ?
                                                    {'margin-right': '0px', 'margin-left': 'auto'} : {})">

                    <div *ngFor="let response of question?.responseOptions.options"
                        [ngClass]="'p-field-checkbox label-' + question.responseOptions.labelAlign">

                        <!--Api saves "selected" as 1 instead of true, parse to visualize box as checked when loading saved checkboxes from api-->
                        <!--As [ngModel] is now input only, need to bind back to component with (ngModelChange)-->
                        <p-checkbox
                          *ngIf="(question.multioptionParent || question.scoringItems)"
                          inputId="{{response.value+'_'+question.itemId}}"
                          name="{{'category_'+question.itemId}}"
                          [disabled]="question.disabled === true"
                          binary="true"
                          value="{{response.value}}"
                          [ngModel]="selectedResponse[question.position].responseValue != 1 ? selectedResponse[question.position].responseValue : true"
                          (ngModelChange)="selectedResponse[question.position].responseValue = $event"
                          (onChange)="onClick(question, true)"
                          falseValue="null">
                        </p-checkbox>

                        <p-radioButton
                          *ngIf="(!question.multioptionParent && !question.scoringItems)"
                          inputId="{{response.value+'_'+question.itemId}}"
                          name="category_+{{question.itemId}}"
                          value="{{response.value}}"
                          [(ngModel)]="selectedResponse[question.position].responseValue"
                          (click)="onClick(question)"
                          (change)="onClick(question)">
                        </p-radioButton>

                        <label class="p-field-label pointer" for="{{response.value+'_'+question.itemId}}" [innerHTML]="response.label"></label>
                    </div>
                </div>
              </div>
            </div>
        </div>
        <!-- Esto es para ver el valor del objeto en tiempo real (para debug) -->
        <!-- {{selectedResponse | json}} -->
    </div>

    <!--Short QCode Reference-->
    <div class="flex justify-content-center qId">
      {{currentTest.qId | padNumber:6}}
    </div>
</div>

<!--FOOTER CONTROL BAR-->
<div class="controls">

  <!--Progress Bar-->
  <div class="progress-bar flex flex-column">
    <div class="flex justify-content-between status">
      <div class="text-left">
        <!--Status-->
        <!-- <span *ngIf="subtestRules?.itemsPerScreen">{{ indexPage + 1 }}/{{ maxIndexPage + 1 }}</span> -->
        <span *ngIf="subtestRules?.itemsPerScreen">{{ getQuestionsMinMax() }}/{{ itemsNumber }}</span>
        <!--Timer-->
        <span *ngIf="subtestRules?.maxTime" class="reloj"
          [ngClass]="{'reloj-end': difference <= 60, 'reloj-medium' : difference > 60 && difference <= 900}">
          <i class="pi pi-clock" style="font-size: 10px;"></i>
          {{timeLeft}}</span>
      </div>

      <div class="text-center">
        <!--Botón desplegable-->
        <p-button
          icon="pi pi-angle-up"
          iconPos="right"
          class="btn-skipped pointer"
          [hidden]="indexNoResp.length == 0"
          (click)="showNonResponseQuestions=true">
        </p-button>
      </div>

      <div class="text-right flex justify-content-end">
        <!--Contador preguntas sin responder-->
        <span class="pointer" (click)="indexNoResp.length == 0 ? null : (showNonResponseQuestions=true)"> {{'TEST.NO-RESPONSES' | translate}}</span>
        <span class="respuestas pointer" (click)="indexNoResp.length == 0 ? null : (showNonResponseQuestions=true)">{{indexNoResp.length}}</span>
      </div>
    </div>

    <!--Barra de progreso-->
    <p-progressBar [value]="valSlider" [showValue]="false" [style]="{'height': '10px', 'border-radius': '20px'}">
    </p-progressBar>
  </div>

  <!--Buttons-->
  <div class="buttons mx-1 my-1">
    <!--Botón Atrás-->
    <div class="button-box">
      <p-button
        label="{{'TEST.BACK' | translate}}"
        icon="pi pi-arrow-left"
        class="btn-question"
        (click)="previousQuestion()"
        [disabled]="!subtestRules.canGoBack || indexPage == 0">
      </p-button>
    </div>

    <div class="button-box">
      <!--Botón siguiente-->
      <p-button
        *ngIf="indexPage<maxIndexPage && enableNextFinish"
        label="{{'TEST.NEXT' | translate}}"
        icon="pi pi-arrow-right"
        iconPos="right"
        class="btn-question"
        [disabled]="!subtestRules.canGoForward && !responseClicked"
        (click)="nextQuestion()">
      </p-button>

      <!--Botón finalizar-->
      <p-button
        *ngIf="indexPage==maxIndexPage && enableNextFinish"
        label="{{'TEST.BTN-FINISH' | translate}}"
        icon="pi pi-arrow-right"
        iconPos="right"
        class="btn-question"
        (click)="checkIfCanSave()">
      <!-- (click)="saveAllResponse(1)"> -->
      </p-button>
    </div>
  </div>

  <!--App version-->
  <span class="app-version my-1 mx-2 text-right">v: {{currentAppVersion}}</span>
</div>

<!--NON RESPONSES QUESTIONS-->
<p-sidebar [(visible)]="showNonResponseQuestions" position="bottom" [blockScroll]="true" [baseZIndex]="10000">
  <ng-template pTemplate="header">
    <span>
      {{'TEST.NO-RESPONSES' | translate | uppercase}}:
      <span class="respuestas">{{indexNoResp.length}}</span>
    </span>
  </ng-template>
  <ng-template pTemplate="content">
    <p class="non-response-question pointer" (click)="showQuestion(question)" *ngFor="let question of indexNoResp">
      <span class="mx-2">
        <span class="question-mandatory" *ngIf="question.mandatory">*</span>
        <span *ngIf="!question.mandatory">&nbsp;</span>
      </span>
      <span><span *ngIf="subtestRules.showQuestionNumber">{{question.scoringItems ? question.index + 1 : question.position + 1 }}. &nbsp; </span>{{question.parsedQuestion}}</span>
    </p>
  </ng-template>
</p-sidebar>

<!--INSTRUCTIONS DIALOG-->
<p-dialog [(visible)]="dialog" [position]="position" [modal]="true" [style]="{width: '100vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header" class="header-dialog">
      <!-- <p>Instrucciones</p> -->
  </ng-template>

  <p class="dialog-instructions" [innerHTML]="instruction"></p>

  <ng-template pTemplate="footer">
    <div class="text-center">
      <button pButton (click)="dialog=false" class="btn-primary" label="{{'INSTRUCTIONS.BTN-OK'|translate}}"></button>
    </div>
  </ng-template>
</p-dialog>


<!--SCROLL BUTTON-->
<button
  *ngIf="showScroll"
  pButton type="button"
  icon="pi pi-angle-down"
  (click)="scrollToBottom()"
  class="show-scroll p-button-rounded">
</button>

<!--SPEED DIALOG TOOLS-->
<p-speedDial [model]="speedDialItems" direction="left" showIcon="pi pi-bars" hideIcon="pi pi-times"></p-speedDial>

<!--CONFIRM DIALOG-->
<p-confirmDialog *ngIf="subtestRules?.maxTime" [baseZIndex]="10000" [rejectVisible]="false"></p-confirmDialog>
<p-confirmDialog *ngIf="!subtestRules?.maxTime" [baseZIndex]="10000"></p-confirmDialog>
<!-- <div (window:beforeunload)="onBeforeUnload()"></div> -->
