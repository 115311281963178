import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ResponseItem } from 'src/models/test/responseItem';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  currentQuestionnaires = new BehaviorSubject<any>(null);
  sharedcurrentQuestionnaires = this.currentQuestionnaires.asObservable();

  currentStructures = new BehaviorSubject<any>(null);
  sharedCurrentStructures = this.currentStructures.asObservable();

  currentTest = new BehaviorSubject<any>(null);
  sharedCurrentTest = this.currentTest.asObservable();

  currentSelectedSubtest = new BehaviorSubject<any>(null);
  sharedCurrentSelectedSubtest = this.currentSelectedSubtest.asObservable();

  currentPrivacyChecked = new BehaviorSubject<boolean>(false);
  sharedCurrentPrivacyChecked = this.currentPrivacyChecked.asObservable();

  currentCountdown = [];

  // New Behaviour subject
  showHelp = new BehaviorSubject<boolean>(false);
  helpShowed = this.showHelp.asObservable();

  forceListReload = false;
  isRecoverableSession = true;
  currentTestFinished = false;

  constructor(
    private localSrv: LocalService
  ) {
    const currentQuestionnaires = this.localSrv.getJsonValue('currentQuestionnaires');
    const currentStructures = this.localSrv.getJsonValue('currentStructures');
    const currentTest = this.localSrv.getJsonValue('currentTestLS');
    const selectedSubTest = this.localSrv.getJsonValue('selectedSubTest');
    const currentCountdown = this.localSrv.getJsonValue('currentCountdown');

    if (currentQuestionnaires) {
      try {
        this.setCurrentQuestionnaires(currentQuestionnaires);
      } catch (ex) {console.log(ex)}
    }

    if (currentStructures) {
      try {
        this.setCurrentStructures(currentStructures);
      } catch (ex) {console.log(ex)}
    }

    if (currentTest) {
      try {
        this.setCurrentTest(currentTest);
      } catch (ex) {console.log(ex)}
    }

    if (selectedSubTest) {
      try {
        this.setCurrentSelectedSubTest(selectedSubTest);
      } catch (ex) {console.log(ex)}
    }

    if (currentCountdown) {
      try {
        this.setCurrentCountdown(currentCountdown);
      } catch (ex) {console.log(ex)}
    }
  }

  setShowHelp(value: boolean){
    this.showHelp.next(value)
  }

  setCurrentQuestionnaires(quest: any) {
    this.localSrv.setJsonValue('currentQuestionnaires', quest);
    this.currentQuestionnaires.next(quest);
  }

  setCurrentStructures(str: any) {
    this.localSrv.setJsonValue('currentStructures', str);
    this.currentStructures.next(str);
  }

  setCurrentTest(test: any) {
    this.localSrv.setJsonValue('currentTestLS', test);
    this.currentTest.next(test);
  }

  setCurrentSelectedSubTest(selectedSubTest: any) {
    try{
      this.localSrv.setJsonValue('selectedSubTest', selectedSubTest);
    }catch (ex) {
      console.error(ex);
    }
    this.currentSelectedSubtest.next(selectedSubTest);
  }

  setCurrentCountdown(countdown: any) {
    this.localSrv.setJsonValue('currentCountdown', countdown);
    this.currentCountdown = countdown;
  }

  clearSubjects(){
    this.setCurrentQuestionnaires(null);
    this.setCurrentStructures(null);
    this.setCurrentTest(null);
    this.setCurrentSelectedSubTest(null);
  }

  orderSubtestItems(subtest): any {
    let auxItems = [];
    if (subtest.subtestRules.itemOrder){
      subtest.subtestRules.itemOrder.forEach(index => {
        auxItems.push(subtest.items.filter(x => x.itemId == +index)[0]);
      });
      subtest.items = auxItems;
    }
    return subtest;
  }

  setPagesIndex(subtest): any {
    let maxItemsPerScreen = +subtest.subtestRules.itemsPerScreen;
    let customIndex = 0;
    let contPerScreen = 0;

    // transforma todos los scoring items de string a enteros
    for (let i = 0; i < subtest.items.length; i++) {
      if (subtest.items[i].scoringItems && subtest.items[i].scoringItems.length > 0) {
        subtest.items[i].scoringItems = subtest.items[i].scoringItems.map(x => +x);
      }
    }

    // inicializa el array
    subtest.items = subtest.items.map(x => ({...x, index: undefined}));

    // para limpiar los arrays vacíos
    for (let i = 0; i < subtest.items.length; i++) {
      if (subtest.items[i].associatedItems && !subtest.items[i].associatedItems.length) {
        subtest.items[i].associatedItems = null;
      }
      if (subtest.items[i].scoringItems && !subtest.items[i].scoringItems.length) {
        subtest.items[i].scoringItems = null;
      }
    }

    for (let i = 0; i < subtest.items.length; i++) {

      if (subtest.items[i].associatedItems !== null) {
        subtest.items = subtest.items.map(x => ( subtest.items[i].associatedItems.includes(x.itemId) ?
        {...x, index: customIndex} : {...x}));

        // comprueba si hay elementos multipleChoiceParent sin index y no rellenos
        let aux = (subtest.items.filter(x => x.scoringItems != null && x.index != undefined && x.filled == undefined));
        // si existen casos, asigna a los childs el index y la referencia
        if(aux.length> 0) {
          aux.forEach(z => {
            subtest.items = subtest.items.map(x => ( z.scoringItems.includes(x.itemId) ?
                               {...x, index: customIndex, multioptionParent: z.itemId} : {...x}));
            // marca el parent como relleno
            for (let u = 0; u < subtest.items.length; u++) {
              if(subtest.items[u].itemId === z.itemId){
                subtest.items[u].filled = true;
              }
            }
          });
        }
      }

      if (subtest.items[i].scoringItems !== null && subtest.items[i].filled === undefined) {
        subtest.items = subtest.items.map(x => ( subtest.items[i].scoringItems.includes(x.itemId) ?
        {...x, index: customIndex, multioptionParent: subtest.items[i].itemId} : {...x}));
        subtest.items[i].filled = true;
      }

      if (subtest.items[i].index === undefined) {
        subtest.items[i].index = customIndex;
        contPerScreen++;
        if (contPerScreen === maxItemsPerScreen || maxItemsPerScreen == 1  || subtest.items.length - i === subtest.items.length % maxItemsPerScreen) {
          customIndex++;
          contPerScreen = 0;
        }
      }
    }

    let regex = /(<([^>]+)>)/gi;

    // generate position to reference ngModel and parsedQuestion
    subtest.items = subtest.items.map((x, i) => ({...x, position: i, parsedQuestion: x.question.replace(regex, '')}));

    // generate subposition to display items of multiplechoice
    let cont = 0;
    for (let i = 0; i < subtest.items.length; i++) {
      if (!subtest.items[i].multioptionParent) {
        subtest.items[i].subposition = cont;
        cont++;
      }
    }

    return {subtest: subtest, maxIndex: customIndex - 1};
  }

  setItemsFormat(subtest): void{
    subtest.items.forEach(item => {
      if (subtest.subtestRules.columns && !item.responseOptions.columns) {
        item.responseOptions.columns = subtest.subtestRules.columns;
      }
      if (subtest.subtestRules.labelAlign && !item.responseOptions.labelAlign) {
        item.responseOptions.labelAlign = subtest.subtestRules.labelAlign;
      }
    });
  }

  formatSendObject(obj: any): any {
    let aux = [];
    obj.forEach(x => {
      let element = {...x};
      if (x.responseValue === true) {
        element.responseValue = 1;
      } else if (x.responseValue === false) {
          element.responseValue = 0;
      } else if (x.responseValue) {
        element.responseValue = parseInt(element.responseValue);
      }
      aux.push(element);
    });
    return aux;
  }

  orderSelectedResponsesByTestRules(order, items): any{

    const selectedResponses = [];

    order.forEach(i => {
      const item = items.filter(x => x.itemId === +i)[0];
      selectedResponses.push(
        ResponseItem.ResponseItemsJson({
          itemId: item.itemId,
          elapsedTime: item.elapsedTime ? item.elapsedTime : '',
          // responseType: item.responseOptions.type,
          responseValue: item.responseValue ? item.responseValue : null
        }));
    });

    return selectedResponses;
  }

  getTestOfTestStructureBasedOnId(structures, subtestId): any {
    let testStructure = null;
    structures.forEach(structure => {
      if ((testStructure === null || testStructure === undefined) && (structure.entryUrl?.includes('assessment') || !structure.entryUrl)) {
        testStructure = structure.subtests.filter(x => x.subtestId === subtestId)[0];
      }
    });
    return testStructure;
  }
}
