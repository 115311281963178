import { NgxCookiebotConfig } from '@halloverden/ngx-cookiebot';
import { environment } from 'src/environments/environment';

export class CookiebotConfig extends NgxCookiebotConfig {
  blockingMode: 'auto' | 'manual' | string='manual';
  cbId: string=environment.cookieBotId;
  culture?: string;
  framework?: string;
  level?: string;
  type?: string;
}