<div class="content">
  <!--TITLES-->
  <div class="privacy-title">
    <h1>{{'HOME.TITTLE' | translate}}</h1>
    <h2>{{'HOME.SUBTITLE' | translate}}</h2>
  </div>
  <!--PRIVACY CONTENT-->
  <div class="privacy-content">
    <!--TEXT 1-->
    <div [innerHtml]="'HOME.TEXT'|translate" class="text"></div>
    <!--TEXT 2 & CONFIRMATION-->
    <div class="privacy-confirm">
      <div class="text-center">
        <img src="assets/images/homeLogo.png" class="img">
      </div>
      <div class="text-justify">
        <div class="mb-5 mt-1">
          <p-checkbox
            [(ngModel)]="statisticsCheck"
            type="checkbox"
            id="condicion"
            name="condicion"
            value="false"
            binary="true">
          </p-checkbox>
          <span class="text text-justify">
            {{'HOME.TITLE-STATISTICS'| translate }} <u (click)="showLegal()" class="pointer">{{'HOME.TITLE-STATISTICS_SECOND'| translate }}</u> {{'HOME.TITLE-STATISTICS_THIRD'| translate }}
          </span>
        </div>
        <div class="text-center">
          <button
            [disabled]="!privacycheck"
            pButton
            class="btn-secondary"
            type="button"
            label="{{'HOME.BTN-OK'|translate}}"
            (click)="goToNextStep()">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="{{'HOME.LEGAL-TITLE' | translate}}" [(visible)]="displayLegal" maximizable="true">
  <div [innerHtml]="'HOME.LEGAL-TEXT' | translate"></div>
</p-dialog>
