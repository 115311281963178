import { Component, OnInit, ElementRef, Input, OnDestroy, AfterViewInit, } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { TestI } from 'src/models/test';
import { TestRules } from 'src/models/test/testrules';
import { TestService } from 'src/app/services/test.service';
import { SubSink } from 'subsink';
import { Observable, Subscription } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ColorChangeService } from 'src/app/services/color-change.service';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [ToastService]
})
export class ListComponent implements OnInit, OnDestroy {
  @Input() dataEntrante: any;
  public data: TestI[] = [];
  public test: boolean = false;
  // public testTitle: string;
  // public completado: boolean = false;
  public btEnviar: boolean[] = [];
  public btDisable: boolean = true;
  public subtest: boolean = false;

  // Puntero del Structure
  public punteroStructure: number = 0;
  // orden de los subtest
  public subtestOrder: any = [];

  // test Rules
  public testRules: TestRules[] = [];

  // contenedor de todos los test del usuario
  public testStructures: any = [];
  public subTestsStructures = [];

  // si es true se solicita las respuestas
  public getResponses: any[] = [];

  subs = new SubSink();
  testSubs = new SubSink();

  public testTitle: any = [];

  questionnaires: any;

  countdown: number;

  currentQuestionnaires: any;

  loaded = false;

  currentProject: any;

  currentTesttaker: any;

  evaluatedPersonToShow = [];

  // determina si el proyecto debe ejecutarse de forma secuencial o no
  isSecuencial = false;

  externalInterval: any;

  constructor(
    private dataSvc: DataService,
    private testSrv: TestService,
    private toastSrv: ToastService,
    private translate: TranslateService,
    private host: ElementRef,
    private router: Router,
    private colorSrv: ColorChangeService
  ) {}

  ngOnInit(): void {

    this.loaded = false;
    // actualiza la información del test, descargando los datos de api
    this.subs.sink = this.testSrv.sharedcurrentQuestionnaires.subscribe(question => {

      if (question !== null) {
        this.dataSvc.getConfigReloaded(question.questionnaires[0].questionnaireId).subscribe(
          res => {

            if (question.questionnaires[0].orderInProject){
              this.isSecuencial = question.isSequential === true;
            }
            this.currentQuestionnaires = res.data;
            this.testSrv.forceListReload = false;
            this.currentTesttaker = res.data.testtaker;

            // DAN-2035: Questionnaires do not have to have an associated project
            this.currentProject = res.data.project;
            if(this.currentProject?.extraInfo?.evaluatedPerson?.evaluatedPersonData) {
              this.evaluatedPersonToShow = JSON.parse(JSON.stringify(this.currentProject.extraInfo.evaluatedPerson.evaluatedPersonData));
              // not shown evaluatedPerson to any evaluatedPerson (check if currentTesttaker is a evaluatedPerson)
              const foundedPerson = this.evaluatedPersonToShow.find(_person => _person.testtaker_id === this.currentTesttaker.id);
              if (foundedPerson){
                this.evaluatedPersonToShow = null;
              }
            }
            this.loadCurrentQuestionnaires();
          },
          err => {
            // proyecto finalizado
            if (err.error.code === 5004) {
              this.router.navigate(['completed', { errorCode: err.error.code}]);
            }
          });
      }
    });

  }

  loadCurrentQuestionnaires(): void {
    if (this.currentQuestionnaires != null) {
      this.questionnaires = this.currentQuestionnaires;
      this.testSubs.sink = this.testSrv.sharedCurrentTest.subscribe(
        (currentTest) => {
          // Si el test seleccionado se trata de un test normal, con un solo subtest
            // R.R Relying on a string inside url, TODO we must refactor hardcoded 'assessment' here
            // For now I put volassessment here as 'assessment'
          if (currentTest == null || currentTest.subtests?.length == 1 || !currentTest.entryUrl?.includes(['assessment']) || currentTest.entryUrl?.includes(['volassessment'])) {
            console.log("loadCurrentQuestionnaires")
            console.log(currentTest?.entryUrl)
              this.subtest = false;
            this.testSubs.sink = this.testSrv.sharedCurrentStructures.subscribe(
              (structures) => {
                if (structures !== null) {
                  // ordenar por qId por defecto
                  structures.sort((a, b) => (a.qId > b.qId) ? 1 : ((b.qId > a.qId) ? -1 : 0));
                  this.testStructures = structures;
                  try {
                    this.testStructures.map((x) => {
                      (x.testTitle = this.currentQuestionnaires.questionnaires.filter((z) => z.id == x.qId)[0].testTitle),
                      (x.subtest = x.subtests?.length > 1 ? true : false),
                      (x.status = this.currentQuestionnaires.questionnaires.filter((z) => z.id == x.qId)[0].status),
                      (x.entryUrl = this.currentQuestionnaires.questionnaires.filter((z) =>{
                        if(z.id === x.qId)
                          return z?.entryUrl;
                      })[0].entryUrl);
                    });
                    this.setTestAndSubtestStatus(this.testStructures, false);
                    this.checkIfTestAutostart();
                    this.forceSecuencialOrder();
                  } catch (err) {console.log(err)}
                }
              }
            );
          } else if (currentTest?.subtests?.length > 1) {
            // Si el test seleccionado contiene más de un subtest
            this.subtest = true;
            // ordenar por subtestId por defecto
            // currentTest.subtests.sort((a, b) => (a.customId > b.customId) ? 1 : ((b.customId > a.customId) ? -1 : 0));
            // ordenar la lista de subtests en caso de que sea necesario
            if (currentTest.testRules.subtestOrder) {
              const ordererSubtest = [];
              currentTest.testRules.subtestOrder.forEach(x => {
                currentTest.subtests.forEach(_subtest => { // Add condition for roleBased tests (not all subtest for each testtaker)
                  if(_subtest.customId === x){
                    ordererSubtest.push(currentTest.subtests.filter(z => z.customId == x)[0]);
                  }
                });
              });
              this.subTestsStructures = ordererSubtest;
            } else {
              this.subTestsStructures = currentTest.subtests;
            }
            this.setTestAndSubtestStatus([currentTest], true);
          }
        }
      );
    }
    this.testSrv.setShowHelp(true);
  }

  iniciarTest(index: number): void {
    // establezco el qCode del test seleccionado
    this.dataSvc.setSharedCurrentQcode(this.testStructures[index].qCode);

    this.testSubs.unsubscribe();

    // establezco el test actual
    this.testSrv.setCurrentTest(this.testStructures[index]);

    if (!this.testStructures[index].subtest) {
      try {
        this.testSubs.unsubscribe();
        // como solo se trata de un test cojo el único subtest
        if(this.testStructures[index].subtests)
          this.testSrv.setCurrentSelectedSubTest(this.testStructures[index].subtests[0].subtestId);
        this.router.navigate(['form']);
      } catch (ex) {
        this.router.navigate(['list']);
      }
    } else {
      this.router.navigate(['instructions']);
    }
  }

  initExternalTest(index: number) {
    // Set loading spinnner
    this.testStructures[index].loading = true;
    // Save previous status
    const prevStatus = this.testStructures[index].status;

    let qCode = this.testStructures[index].qCode;

    // Get external data based in qCode
    this.dataSvc.getQuestionnaireExternalData(qCode).subscribe({
      next: (res) => {
        //open a new mbti tab with the data as queryparams
        const url = this.router.createUrlTree(['/mbti/'], {
          queryParams: res.data
        });
        window.open(url.toString(), '_blank');

        // Set test as init for our system
        this.dataSvc.startQuestionnaire(qCode).subscribe(resp => {
          console.log(resp)
        })

        // Start test status observer interval
        this.generateIntervalToCheckExternalTest(this.testStructures[index]);
      },
      error: (err) => {
        this.toastSrv.showToastError(err.message);
        // Reset to previous test status
        this.testStructures[index].status = prevStatus;
      }
    }).add(() => {
        // Cancel loading spinner
        this.testStructures[index].loading = false;
        // Set test as started
        this.testStructures[index].status = 21;
    });
  }

  iniciarSubTest(index: number): void {
    this.testSubs.unsubscribe();
    this.testSrv.setCurrentSelectedSubTest(this.subTestsStructures[index].subtestId);
    this.router.navigate(['form']);
  }

  goBack(): void{
    // reestablezco el currentTest
    this.testSrv.setCurrentTest(null);
    this.router.navigate(['list']);
  }

  /**
   * 20: Asignado
   * 21: En progreso
   * 22: Finalizado
   * @param testStructures;
   */
  setTestAndSubtestStatus(testStructures, isSubtest): void {
    testStructures.forEach(test => {
      if (test.status < 22) {
        this.dataSvc.getQuestionAnswered(test.qCode)
        .then(res => {
          if (res.entryLastPoint) {
            test.subtests.forEach(subtest => {
              const sbtEntryLastPoint = res.entryLastPoint.find(x => x.subtestId === subtest.subtestId || x.customId === subtest.customId);

              // Si el subtest tiene la regla recoverableSession
              if (!subtest.subtestRules.recoverableSession && sbtEntryLastPoint) {
                subtest.status = 22; // muestra el subtest como finalizado
                // this.saveFinishedQuestionnaire(test, res, sbtEntryLastPoint);
              } else if (sbtEntryLastPoint?.finished === true) { // si el subtest ha sido relleno
                subtest.status = 22; // Finalizado
              } else if (sbtEntryLastPoint?.finished === false) {
                subtest.status = 21; // En proceso
              } else {
                subtest.status = 20; // Asignado
              }
            });

            // establecer el estado del test en base al estado de los subtests
            test.status =  test.subtests.filter(x => x.status === 21 || x.status === 20).length > 0
              ? 21 : Math.min(...test.subtests.map(x => x.status));

            // si todos los subtests están completados y no se ha envíado aún
            // if (test.subtests.length === res.entryLastPoint.filter(x => x.finished).length && test.status > 21) {
            //   this.saveFinishedQuestionnaire(test, res, res.entryLastPoint);
            // }
          } else {
            if (test.subtests?.length > 1) {
              test.subtests = test.subtests.map(x => ({...x, status:  20}));
              test.status = 20; // si no hay rastro anterior establezco el test como asignado
            }
          }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        if (isSubtest === true){
          this.checkIfSubtestAutostart();
        }
        this.forceFinishQuestionnaires(test);
        this.loaded = true;

        // Check if all test are marked as finished
        if(testStructures.filter(_test => _test.status > 21).length == testStructures.length){
          // Check if exist toCallUrl
          this.dataSvc.checkToCallUrl();
        }
      });
      }
    });
  }

  saveFinishedQuestionnaire(test, res, sbtEntryLastPoint): void {
    const questionnaireJson = {
      testId: test.test_id,
      questionnaireResponses: res.questionnaireResponses,
      entryLastPoint: sbtEntryLastPoint,
    };
    this.dataSvc.sendDataAndCloseQuestionnaire(questionnaireJson, test.qCode);
  }

  ngAfterViewInit(): void {
    this.colorSrv.setIceBlueBackground();
  }

  checkIfTestAutostart(): void {
    if (this.testStructures.length === 1 && this.testStructures[0].testRules.autostart) {
      // Inicia el primero, puesto que solo hay uno
      this.iniciarTest(0);
    } else {
      // borra las posibles referencias de un subtest seleccionado previamente
      this.testSrv.setCurrentSelectedSubTest(null);
    }
  }

  checkIfSubtestAutostart(): void {
    this.testSubs.sink = this.testSrv.sharedCurrentStructures.subscribe(
      (structures) => {
        this.testSubs.sink = this.dataSvc.sharedCurrentQcode.subscribe(qCode => {
          if (structures !== null && qCode !== null) {
            if (structures.filter(x => x.qCode === qCode)[0]?.testRules.autostart) {
              let startFlag = false;
              this.subTestsStructures.forEach((subtest, index) => {
                // contemplar tambien el 21 si se quisiera regresar a la lista
                // if ((subtest.status === 20 || subtest.status === 21) && !startFlag) {
                if ((subtest.status === 20 || !subtest.status) && !startFlag) {
                  subtest.disabled = false;
                  startFlag = true;
                  this.iniciarSubTest(index);
                } else if (subtest.status === 21) {
                  subtest.disabled = false;
                  startFlag = true;
                } else {
                  subtest.disabled = true;
                }
              });
            }
          }
        });
      }
    );
  }

  forceSecuencialOrder(): void {
    if (this.isSecuencial){
      let startFlag = false;
      this.testStructures.forEach((test, index) => {
        // contemplar tambien el 21 si se quisiera regresar a la lista
        if ((test.status === 20 || test.status === 21 || !test.status) && !startFlag) {
          test.disabled = false;
          startFlag = true;
        } else {
          test.disabled = true;
        }
      });
    }
  }

  /**
   * Si existen cuestionarios previos finalizados en base a los datos de entryLastPoint,
   * manda a finalizar el test para evitar posibles problemas de cierre de navegador antes
   * confirmar anteriormente
   */
  forceFinishQuestionnaires(test): void {
    if(test.status>21){
      this.dataSvc.addAllresponse(null, test.qCode).toPromise().then( res => {
        console.log('Questionnaire finalished', test.qCode);
      }).catch(err => {
        if(err.error?.code === 5001){
          this.toastSrv.showToastSucess(this.translate.instant('LIST.FINISHED-ALERT') + test.testTitle);
        }
      });
    } else if (test.status == 21 && test.external) { // External started test: Check if the test can be finished
      test.loading = true;
      this.finishExternalQuestionnaire(test);
    }
  }

  /**
   * Generate an interval that check if the test is completed
   * @param test
   */
  generateIntervalToCheckExternalTest(test){
    this.externalInterval = setInterval(() => {
      this.finishExternalQuestionnaire(test);
    },2000*60); // Every 2 minutes
  }

  finishExternalQuestionnaire(test) {
    this.dataSvc.getQuestionnaireExternalStatus(test.qCode).subscribe({
      next: (res) => {
        if(res.data === 'COMPLETED'){
          // finish test
          this.dataSvc.addAllresponse({}, test.qCode).subscribe(res => {
            // refresh same view
            location.reload();
          });
        }
      },
      error: (err) => {
        this.toastSrv.showToastError(err.message);
      }
    }).add(() => {
      test.loading = false;
    })
  }

  ngOnDestroy(): void {
    this.colorSrv.setDefaultBackground();
    this.testSubs.unsubscribe();
    this.subs.unsubscribe();
    clearInterval(this.externalInterval);
  }
}
