import { ResponseItem } from "./responseItem";


export class QuestionnaireResponse {
    static QuestionnaireResponseJson(obj: Object){
        return new QuestionnaireResponse(
         
            obj['subtestId'],
            obj['elapsedTime'],
            obj['maxTime'],
            obj['remaningTime'],
            obj['responseItems'],
        );
    }
    
    constructor(
    public subtestId:   string,
    public elapsedTime:   number,
    public maxTime:      number,
    public remaningTime:  number,
    public responseItems: ResponseItem[],
   ){

    }

    
}