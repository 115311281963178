import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { SliderModule } from 'primeng/slider';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { PasswordModule } from 'primeng/password';
import { SpeedDialModule } from 'primeng/speeddial';
import { TooltipModule } from 'primeng/tooltip';

const primeNgModules = [
  ButtonModule,
  MenubarModule,
  MenuModule,
  RadioButtonModule,
  InputTextModule,
  InputNumberModule,
  SliderModule,
  CheckboxModule,
  CardModule,
  AvatarModule,
  AvatarGroupModule,
  ToastModule,
  ProgressBarModule,
  DialogModule,
  AccordionModule,
  ScrollPanelModule,
  DropdownModule,
  TagModule,
  ConfirmDialogModule,
  ProgressSpinnerModule,
  SidebarModule,
  PasswordModule,
  SpeedDialModule,
  TooltipModule
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    primeNgModules
  ],
  exports: [
    primeNgModules
  ]
})
export class PrimengModule { }
