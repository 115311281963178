<!--Title-->
<h1 class="text-center">{{'HELP.HELP' | translate}}</h1>

<!--Back Row-->
<div class="pi pi-arrow-left arrow mb-4 ml-2" (click)="goBack()"></div>

<div class="help">
  <!--Content-->
  <div class="help-content">
    <div>
      <h2 id="giunti">{{'HELP.GIUNTI' | translate}}</h2>
    </div>
    <p-accordion [multiple]="true">
      <p-accordionTab id="whatIsGiunti" header="{{'HELP-QUESTION.WHAT-IS' | translate}}" [(selected)]="activeState[0]">
        <div [innerHtml]="'HELP-TEXT.WHAT-IS' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='how-work' header="{{'HELP-QUESTION.HOW-WORK' | translate}}" [(selected)]="activeState[1]">
        <div [innerHtml]="'HELP-TEXT.HOW-WORK' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='access' header="{{'HELP-QUESTION.HOW-ACCESS' | translate}}" [(selected)]="activeState[2]">
        <div [innerHtml]="'HELP-TEXT.HOW-ACCESS' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='safe' header="{{'HELP-QUESTION.IS-SAFE' | translate}}" [(selected)]="activeState[3]">
        <div [innerHtml]="'HELP-TEXT.IS-SAFE' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='treatment' header="{{'HELP-QUESTION.DATA-TREATMENT' | translate}}" [(selected)]="activeState[4]">
        <div [innerHtml]="'HELP-TEXT.DATA-TREATMENT' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='recovery' header="{{'HELP-QUESTION.DATA-RECOVERY' | translate}}" [(selected)]="activeState[5]">
        <div [innerHtml]="'HELP-TEXT.DATA-RECOVERY' | translate"></div>
      </p-accordionTab>
    </p-accordion>

    <div>
      <h2 id='access'>{{'HELP.FUNCTION-TRIALS' | translate}}</h2>
    </div>

    <p-accordion [multiple]="true">
      <p-accordionTab id='instructions' header="{{'HELP-QUESTION.INSTRUCTIONS' | translate}}" [(selected)]="activeState[6]">
        <div [innerHtml]="'HELP-TEXT.INSTRUCTIONS' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='possible' header="{{'HELP-QUESTION.POSSIBLE-QUESTIONS' | translate}}" [(selected)]="activeState[7]">
        <div [innerHtml]="'HELP-TEXT.POSSIBLE-QUESTIONS' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='tempstop' header="{{'HELP-QUESTION.TEMPORARY-STOP' | translate}}" [(selected)]="activeState[8]">
        <div [innerHtml]="'HELP-TEXT.TEMPORARY-STOP' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='saveresponses' header="{{'HELP-QUESTION.SAVE-RESPONSES' | translate}}" [(selected)]="activeState[9]">
        <div [innerHtml]="'HELP-TEXT.SAVE-RESPONSES' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='answerallquestions' header="{{'HELP-QUESTION.ANSWER-ALL-QUESTIONS' | translate}}" [(selected)]="activeState[10]">
        <div [innerHtml]="'HELP-TEXT.ANSWER-ALL-QUESTIONS' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='recovernon' header="{{'HELP-QUESTION.RECOVER-NON-ANSWERED' | translate}}" [(selected)]="activeState[11]">
        <div [innerHtml]="'HELP-TEXT.RECOVER-NON-ANSWERED' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='endtest' header="{{'HELP-QUESTION.END-TEST' | translate}}" [(selected)]="activeState[12]">
        <div [innerHtml]="'HELP-TEXT.END-TEST' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='report' header="{{'HELP-QUESTION.REPORT' | translate}}" [(selected)]="activeState[13]">
        <div [innerHtml]="'HELP-TEXT.REPORT' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='backgiunti' header="{{'HELP-QUESTION.BACK-TO-GIUNTI' | translate}}" [(selected)]="activeState[14]">
        <div [innerHtml]="'HELP-TEXT.BACK-TO-GIUNTI' | translate"></div>
      </p-accordionTab>

    </p-accordion>

    <div>
      <h2 id='manage'>{{'HELP.BATTERY-TRIALS' | translate}}</h2>
    </div>
    <p-accordion [multiple]="true">
      <p-accordionTab id='testlist' header="{{'HELP-QUESTION.TEST-LIST' | translate}}" [(selected)]="activeState[15]">
        <div [innerHtml]="'HELP-TEXT.TEST-LIST' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='testorder' header="{{'HELP-QUESTION.TEST-ORDER' | translate}}" [(selected)]="activeState[16]">
        <div [innerHtml]="'HELP-TEXT.TEST-ORDER' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='sureofcompleted' header="{{'HELP-QUESTION.SURE-OF-COMPLETED' | translate}}" [(selected)]="activeState[17]">
        <div [innerHtml]="'HELP-TEXT.SURE-OF-COMPLETED' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='differentinterface' header="{{'HELP-QUESTION.DIFFERENT-INTERFACE' | translate}}" [(selected)]="activeState[18]">
        <div [innerHtml]="'HELP-TEXT.DIFFERENT-INTERFACE' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='differentwindow' header="{{'HELP-QUESTION.DIFFERENT-WINDOW' | translate}}" [(selected)]="activeState[19]">
        <div [innerHtml]="'HELP-TEXT.DIFFERENT-WINDOW' | translate"></div>
      </p-accordionTab>
    </p-accordion>
    <div>
      <h2 id='manage_project'>{{'HELP.TIME-TRIALS' | translate}}</h2>
    </div>
    <p-accordion [multiple]="true">
      <p-accordionTab id='cronotest' header="{{'HELP-QUESTION.CRONO-TEST' | translate}}" [(selected)]="activeState[20]">
        <div [innerHtml]="'HELP-TEXT.CRONO-TEST' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='timetest' header="{{'HELP-QUESTION.TIME-TEST' | translate}}" [(selected)]="activeState[21]">
        <div [innerHtml]="'HELP-TEXT.TIME-TEST' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='countdown' header="{{'HELP-QUESTION.COUNTDOWN-START' | translate}}" [(selected)]="activeState[22]">
        <div [innerHtml]="'HELP-TEXT.COUNTDOWN-START' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='layoff' header="{{'HELP-QUESTION.LAY-OFF-TEST' | translate}}" [(selected)]="activeState[23]">
        <div [innerHtml]="'HELP-TEXT.LAY-OFF-TEST' | translate"></div>
      </p-accordionTab>

      <p-accordionTab id='conexion' header="{{'HELP-QUESTION.CONEXION-PROBLEMS' | translate}}" [(selected)]="activeState[24]">
        <div [innerHtml]="'HELP-TEXT.CONEXION-PROBLEMS' | translate"></div>
      </p-accordionTab>
    </p-accordion>

  </div>

  <!--Index-->
  <div class="help-index">
    <br>
    <div>
      <h4 (click)="anchorTo('giunti')">{{'HELP.GIUNTI' | translate}}</h4>
    </div>
    <ol>
      <li><span class="subindex" (click)="anchorTo('whatIsGiunti'); toggle(0)">{{'HELP-QUESTION.WHAT-IS' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('how-work'); toggle(1)">{{'HELP-QUESTION.HOW-WORK' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('access'); toggle(2)">{{'HELP-QUESTION.HOW-ACCESS' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('safe'); toggle(3)">{{'HELP-QUESTION.IS-SAFE' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('treatment'); toggle(4)">{{'HELP-QUESTION.DATA-TREATMENT' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('recovery'); toggle(5)">{{'HELP-QUESTION.DATA-RECOVERY' | translate}}</span></li>
    </ol>
    <br>
    <div>
      <h4 (click)="anchorTo('access')">{{'HELP.FUNCTION-TRIALS' | translate}}</h4>
    </div>
    <ol>
      <li><span class="subindex" (click)="anchorTo('instructions'); toggle(6)">{{'HELP-QUESTION.INSTRUCTIONS' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('possible'); toggle(7)">{{'HELP-QUESTION.POSSIBLE-QUESTIONS' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('tempstop'); toggle(8)">{{'HELP-QUESTION.TEMPORARY-STOP' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('saveresponses'); toggle(9)">{{'HELP-QUESTION.SAVE-RESPONSES' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('answerallquestions'); toggle(10)">{{'HELP-QUESTION.ANSWER-ALL-QUESTIONS' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('recovernon'); toggle(11)">{{'HELP-QUESTION.RECOVER-NON-ANSWERED' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('endtest'); toggle(12)">{{'HELP-QUESTION.END-TEST' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('report'); toggle(13)">{{'HELP-QUESTION.REPORT' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('backgiunti'); toggle(14)">{{'HELP-QUESTION.BACK-TO-GIUNTI' | translate}}</span></li>
    </ol>
    <br>
    <div>
      <h4 (click)="anchorTo('manage')">{{'HELP.BATTERY-TRIALS' | translate}}</h4>
    </div>
    <ol>
      <li><span class="subindex" (click)="anchorTo('testlist'); toggle(15)">{{'HELP-QUESTION.TEST-LIST' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('testorder'); toggle(16)">{{'HELP-QUESTION.TEST-ORDER' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('sureofcompleted'); toggle(17)">{{'HELP-QUESTION.SURE-OF-COMPLETED' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('differentinterface'); toggle(18)">{{'HELP-QUESTION.DIFFERENT-INTERFACE' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('differentwindow'); toggle(19)">{{'HELP-QUESTION.DIFFERENT-WINDOW' | translate}}</span></li>
    </ol>
    <br>
    <div>
      <h4 (click)="anchorTo('manage_project')">{{'HELP.TIME-TRIALS' | translate}}</h4>
    </div>
    <ol>
      <li><span class="subindex" (click)="anchorTo('cronotest'); toggle(20)">{{'HELP-QUESTION.CRONO-TEST' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('timetest'); toggle(21)">{{'HELP-QUESTION.TIME-TEST' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('countdown'); toggle(22)">{{'HELP-QUESTION.COUNTDOWN-START' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('layoff'); toggle(23)">{{'HELP-QUESTION.LAY-OFF-TEST' | translate}}</span></li>
      <li><span class="subindex" (click)="anchorTo('conexion'); toggle(24)">{{'HELP-QUESTION.CONEXION-PROBLEMS' | translate}}</span></li>
    </ol>
    <br>
  </div>
</div>
