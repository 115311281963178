import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private messageSrv: MessageService, private translate: TranslateService) { }

  showToastSucess(text: string): void{
    this.messageSrv.add(
      {severity: 'success', detail: text, key: 'toast-sucess'});
  }

  showToastWarning(text: string): void{
    this.messageSrv.add(
      {severity: 'warn', detail: text, key: 'toast-sucess'});
  }

  showToastError(text: string): void{
    // Get close button text
    const btnText = this.translate.instant('GENERAL.CLOSE');

    this.messageSrv.add(
      {severity: 'error', detail: text, summary: btnText, key: 'toast-error', sticky: true, life: 100000000});
  }

  closeToastError(): void {
    this.messageSrv.clear('toast-error');
  }
}
