import { QuestionnaireResponse } from './questionnaireResponse';
export class QuestionnaireData {
    static QuestionnaireDataJson(obj: Object){
        return new QuestionnaireData(
            obj['questionnaireResponses'],
           
        );
    }
    
    constructor(
    public  questionnaireResponses: QuestionnaireResponse[],
   ){

    }

    
}