import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  private selectedHelp = new BehaviorSubject<any>(null);
  sharedSelectedHelp = this.selectedHelp.asObservable();

  constructor() { }

  setSelectedHelp(item: any): void {
    this.selectedHelp.next(item);
  }
}
